<template>
  <div style="padding-top: 3rem;">
    <div class="form-group">
      <div class="title">{{selectWalletLabel}}</div>
      <select class="form-control" v-model="selected_wallet_index">
        <option v-for="(wallet, index) in wallets" :key="index" :value="index">{{wallet.currency}}</option>
      </select>
      <p class="withdraw-balance" v-if="wallet_currency && blockchain_fee">
        <span>
          {{wallet_currency}} {{blockchainFeeLabel}}: {{blockchain_fee}}
          <br>
          {{availableWithdrawLabel}}: {{available_withdraw_amount}}
        </span>
        <b v-if="!call_api_balance">{{balanceLabel}}: {{wallet_balance}}</b>
        <!-- <b v-else>Searching balance</b> -->
      </p>
    </div>
    <div v-if="wallets[selected_wallet_index].currency == 'PPX' && type != 'admin'">
      <div class="tag-wrapper mb-2">
        <div @click="ppxAction = 'convert'" :class="`tag ${ppxAction == 'convert' ? 'primary' : ''}`">{{conversionLabel}}</div>
        <div @click="ppxAction = 'withdrawal'" :class="`tag ${ppxAction == 'withdrawal' ? 'primary' : ''}`">{{withdrawalLabel}}</div>
      </div>
    </div>
    <div v-if="ppxAction == 'convert' && wallets[selected_wallet_index].currency == 'PPX'">
      <p class="title" style="margin-bottom: 1rem;">{{ amountToConvertLabel }}</p>
      <div class="two-col-input">
        <div class="form-group relative">
          <input type="number" min="0.00000001" step="0.00000001" class="form-control" v-model="amountToCovert">
          <span class="absolute-currency">PPX</span>
        </div>
        <div class="form-group relative">
          <input type="text" class="form-control" :value="amountToCovert * (ppxToMyrRate / 100)" disabled="true">
          <span class="absolute-currency">AMYR</span>
        </div>
      </div>
      <p style="margin: 0 1rem 0 0;">{{ conversionLabel }}: 1PPX = {{1 * (ppxToMyrRate / 100)}} AMRY</p>
      <p v-if="error_message" class="text-danger" style="margin-bottom: 1.5rem;">{{error_message}}</p>
      <div @click="convert" class="btn btn-primary btn-block" style="padding: 1rem;">{{convertLabel}}</div>
    </div>
    <div v-if="(ppxAction == 'withdrawal' && wallets[selected_wallet_index].currency == 'PPX') || wallets[selected_wallet_index].currency != 'PPX'" >
      <div class="form-group">
        <div class="title">{{recipientAddressLabel}}</div>
        <input type="text" class="form-control" v-model="recipient_address">
      </div>
      <div class="form-group" style="margin-bottom: 1rem;">
        <div class="title">{{amountLabel}}</div>
        <input type="number" class="form-control" v-model="amount" step="0.00000001" min="0.00000001">
      </div>
      <p v-if="error_message" class="text-danger" style="margin-bottom: 1.5rem;">{{error_message}}</p>
      <div @click="withdraw" class="btn btn-primary btn-block" style="padding: 1rem;">{{withdrawLabel}}</div>
    </div>
  </div>
</template>

<script>
  export default {
    props: [
      'wallets', 
      'token', 
      'type',
      'selectWalletLabel',
      'blockchainFeeLabel',
      'availableWithdrawLabel',
      'balanceLabel',
      'recipientAddressLabel',
      'amountLabel',
      'withdrawLabel',
      'conversionLabel',
      'withdrawalLabel',
      'amountToConvertLabel',
      'convertLabel',
      'conversationMessage',
      'ppxToMyrRate',
      'locale'
    ],
    data() {
      return {
        balance: null,
        withdrawing: false,
        converting: false,
        call_api_balance: true,
        selected_wallet_index: 0,
        wallet_id: null,
        wallet_balance: null,
        wallet_aaddress: null,
        wallet_currency: null,
        blockchain_fee: null,
        recipient_address: null,
        error_message: '',
        amount: null,
        available_withdraw_amount: 0,
        ppxAction: 'withdrawal',
        amountToCovert: 0,
        fee: {
          UBNK: 0.05,
          XIF: 0.05,
          UBG: 0.01,
          AUSD: 0.001,
          AMYR: 0.001,
        }
      }
    },
    computed: {},
    watch: {
      selected_wallet_index: function(val) {
        this.wallet_id = this.wallets[val].id;
        this.wallet_address = this.wallets[val].address;
        this.wallet_currency = this.wallets[val].currency;
        this.blockchain_fee = this.fee[this.wallet_currency];
        this.getWalletDetail();
      },
      wallet_balance: function(val) {
        this.verify_balance_is_enough(val);
      },
      amount: function(val) {
        this.verify_balance_is_enough(val);
      },
      amountToCovert: function(val) {
        this.verify_balance_is_enough(val);
      }
    },
    mounted() {
      if(typeof this.wallets != 'undefined') {
        this.wallet_id = this.wallets[0].id;
        this.wallet_address = this.wallets[0].address;
        this.wallet_currency = this.wallets[0].currency;
        this.blockchain_fee = this.fee[this.wallet_currency];
        this.getWalletDetail();
      }
    },
    methods: {
      getWalletDetail() {
        let vm = this;
        this.call_api_balance = true;
        this.$http.get(this.$apiURL+'/coin/'+this.wallet_address+'?pagesize=10&pageindex=1')
        .then(response => {
          vm.wallet_balance = response.data.account.amount;
          if(vm.wallet_balance > vm.blockchain_fee) {
            vm.amount = vm.wallet_balance - vm.blockchain_fee
          }
          else {
            vm.amount = 0
            vm.available_withdraw_amount = 0;
          }
          vm.call_api_balance = false;
        })
        .catch(error => {
          console.log(error);
        });
      },
      verify_balance_is_enough(amount) {
        
        if(amount) {
          if(this.wallet_balance == 0) {
            this.error_message = this.locale == 'cn' ? '钱包余额不足' : 'Wallet balance is not enought to withdraw';
            this.available_withdraw_amount = 0;
          }
          else {
            var balance = this.wallet_balance * 100000000;
            var amount = amount * 100000000;
            var blockchain_fee = this.blockchain_fee * 100000000;
            this.available_withdraw_amount = (Number((balance - blockchain_fee) / 100000000).toFixed(8));

            var withdraw_amount = (Number((balance - (amount + blockchain_fee)) / 100000000).toFixed(8));
            if(amount && withdraw_amount >= 0) {
              this.error_message = '';
            }
            else {
              this.error_message = this.locale == 'cn' ? '钱包余额不足' : 'Wallet balance is not enought to withdraw';
            }
          }
        }
      },
      validation() {
        var error_found = false;
        if(!this.recipient_address || this.recipient_address == '') {
          error_found = true;
          this.$toast.open({
            message: this.locale == 'en' ? 'Recipient address should not be empty' : '收件人地址不能为空',
            type: 'error',
            position: 'top-right'
          });
        }

        if(!this.amount && this.amount == '' || this.amount <= 0) {
          error_found = true;
          this.$toast.open({
            message: this.locale == 'en' ? 'Withdraw amount should not be empty & should not be lower then 0' : '提款金额不能为空且不能小于0',
            type: 'error',
            position: 'top-right'
          });
        }

        if (this.error_message != "") {
          error_found = true
          this.$toast.open({
            message: this.error_message,
            type: 'error',
            position: 'top-right'
          });
        }
        return !error_found;
      },
      convertValidation() {
        var error_found = false;
        if(!this.amountToCovert && this.amountToCovert == '' || this.amountToCovert <= 0) {
          error_found = true;
          this.$toast.open({
            message: this.locale == 'en' ? 'Convert amount should not be empty & should not be lower then 0' : '兑换金额不能为空且不能小于0',
            type: 'error',
            position: 'top-right'
          });
        }

        if (this.error_message != "") {
          error_found = true
          this.$toast.open({
            message: this.error_message,
            type: 'error',
            position: 'top-right'
          });
        }
        return !error_found;
      },
      withdraw() {
        if(this.validation()) {
          let vm = this;
          let formData = {
            transaction: {
              recipient_address: this.recipient_address,
              amount: this.amount
            },
            authenticity_token: this.token
          };
          if(!this.withdrawing && !this.converting) {
            vm.withdrawing = true;
            this.$http.post('/api/v1/'+(this.type == 'admin' ? 'admin/' : '')+'wallets/'+this.wallet_id+'/send_coin', formData)
            .then(response => {
              vm.$toast.open({
                message: vm.locale == 'en' ? 'Withdraw successful' : '提款成功',
                type: 'success',
                position: 'top-right'
              });
              window.location.reload();
            })
            .catch(error => {
              vm.withdrawing = false;
              vm.$toast.open({
                message: vm.locale == 'en' ? 'Something wrong' : '不好意思，系统出问题',
              type: 'error',
                position: 'top-right'
              });
              console.log(error);
            });
          }
        }
      },
      convert() {
        // if(this.convertValidation()) {
          let vm = this;
          let formData = {
            conversion_request: {
              wallet_id: this.wallet_id,
              amount: this.amountToCovert
            },
            authenticity_token: this.token
          };
          if(!this.converting && !this.withdrawing) {
            vm.converting = true;
            // to do fix 
            this.$http.post('/api/v1/conversion_requests', formData)
            .then(response => {
              vm.$toast.open({
                message: vm.locale == 'en' ? 'Convert request submitted successful' : '兑换申请成功',
                type: 'success',
                position: 'top-right'
              });
              window.location.reload();
            })
            .catch(error => {
              vm.converting = false;
              vm.$toast.open({
                message: vm.locale == 'en' ? 'Something wrong' : '不好意思，系统出问题',
              type: 'error',
                position: 'top-right'
              });
              console.log(error);
            });
          }
        // }
      }
    },
  }
</script>

<style scoped>
.text-danger {
  margin-bottom: .5rem;
}
.relative {
  position: relative;
}
.absolute-currency {
  position: absolute;
  right: .5rem;
  top: .75rem;
}
</style>