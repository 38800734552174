<template>
  <div :class="'add-to-cart-control '+page">
    <div class="availability">
      <span class="btn-cart minus" @click="minus">-</span>
      <b>{{count}}</b>
      <span class="btn-cart plus" @click="plus">+</span>
    </div>
    <div v-if="page == 'explore'" @click="addCart" class="btn btn-primary">
      <span v-show="adding_to_cart" class="loader loading"></span>
      <span v-show="!adding_to_cart">{{addCartBtn}}</span>
    </div>
    <div v-if="page == 'cart'" @click="removeItem" class="btn btn-primary">
      {{removeBtn}}
    </div>
  </div>
</template>

<script>
  export default {
    props: [
      'productId', 
      'cartId', 
      'token', 
      'page', 
      'quantity',
      'addCartBtn',
      'removeBtn',
      'removeConfirmMsg',
      'updateCartSuccess'
    ],
    data() {
      return {
        adding_to_cart: false,
        count: 1,
      }
    },
    watch: {},
    mounted() {
      if(typeof this.quantity != 'undefined') {
        this.count = parseInt(this.quantity);
      }
    },
    methods: {
      minus() {
        if(this.count > 1) {
          this.count -= 1;
          if(this.page == 'cart') {
            this.updateCart();
          }
        }
        else if(this.page == 'cart') {
          this.removeItem();
        }
      },
      plus(){
        this.count += 1;
        if(this.page == 'cart') {
          this.updateCart();
        }
      },
      addCart(e) {
        if(!this.adding_to_cart) {
          this.adding_to_cart = true;
          var formData = {
            authenticity_token: this.token,
            cart_items: {
              product_id: this.productId,
              quantity: this.count,
              // product_variant_id: '01c84e3e-e1e1-4303-9e33-5433da9d93e7'
            }
          };
          var vm = this;
          this.$http.post('/api/v1/cart_items', formData)
          .then(response => {
            vm.adding_to_cart = false;
            $('#success_tic').addClass('show');
            $('#success_tic .checkmark').addClass('draw');
            setTimeout(function(){ 
              $('#success_tic').removeClass('show');
              $('#success_tic .checkmark').removeClass('draw');
            }, 2000);
            vm.updateCartCount();
          })
          .catch(error => {
            console.log(error);
          });
        }
      },
      removeItem(e){
        var r = confirm(this.removeConfirmMsg);
        if(r == true) {
          $('#'+this.cartId).remove();
          this.count = 0;
          this.updateCart();
        }
      },
      updateCart() {
        var formData = {
          authenticity_token: this.token,
          cart_items: {
            product_id: this.productId,
            quantity: this.count
          }
        };
        var vm = this;
        this.$http.put('/api/v1/cart_items/'+this.cartId, formData)
        .then(response => {
          var cart = response.data.data.cart;
          var currency = cart.total_price_currency;
          var subtotal = (Number((cart.subtotal_price_satoshi) / 100000000).toFixed(8));
          var tax_amount = (Number((cart.tax_amount_satoshi) / 100000000).toFixed(8));
          var total_price = (Number((cart.total_price_satoshi) / 100000000).toFixed(8));
          const discount_price = (Number((cart.discount_amount_satoshi) / 100000000).toFixed(8));
          $('.subtotal').text(currency + subtotal);
          $('.tax_amount').text(currency + tax_amount);
          $('.total_price').text(currency + total_price);
          $('.discount_price').text(currency + discount_price);
          if($('.item-row').length == 0) {
            window.location.reload();
          }
          else {
            vm.$toast.open({
              message: vm.updateCartSuccess,
              type: 'success',
              position: 'top-right'
            });
          }
        })
        .catch(error => {
          console.log(error);
        });
      },
      updateCartCount(){
        this.$http.get('/api/v1/cart_items/count')
        .then(response => {
          $('.cart-count').text(response.data.data);
        })
        .catch(error => {
          console.log(error);
        });
      }
    },
  }
</script>

<style scoped>
.availability {
  transform: scale(1.5);
  margin-bottom: 15px;
}
.availability .btn-cart {
  margin: 0 .75rem;
  padding: 0 .25rem;
  cursor: pointer;
  transition: all 0.3s;
}
.availability .btn-cart:hover {
  cursor: pointer;
  color: #f9b73e;
}
.explore .btn-primary {
  padding: 1rem;
}
</style>