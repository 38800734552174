<template>
  <div>
    <div v-if="inventory_transactions.length == 0" class="empty-message">
      {{ emptyMessage }}
    </div>
    <table v-else>
      <tr>
        <th>{{ dateLabel }}</th>
        <th>{{ remarkLabel }}</th>
        <th>{{ skuLabel }}</th>
        <th>{{ quantityLabel }}</th>
        <th v-if="showControl == true"></th>
      </tr>
      <tr
        v-for="(transaction, index) in inventory_transactions"
        :key="`transaction${index}`"
        class="transaction-row"
      >
        <td>
          <span v-if="locale == 'cn'">
            {{ transaction.updated_at.getFullYear() }}年{{
              transaction.updated_at.getMonth() + 1
            }}月{{ transaction.updated_at.getDate() }}日
            {{ transaction.updated_at.getHours() }}:{{
              transaction.updated_at.getMinutes()
            }}
          </span>
          <span v-else>
            {{ transaction.updated_at.getDate() }}
            {{ months[transaction.updated_at.getMonth()] }},
            {{ transaction.updated_at.getFullYear() }}
            {{ transaction.updated_at.getHours() }}:{{
              transaction.updated_at.getMinutes()
            }}
          </span>
        </td>
        <td>
          <img
            :src="
              transaction.product.featured_photo.url
                ? transaction.product.featured_photo.url
                : placeholderImage
            "
            class="thumb"
          />
          {{ transaction.product.name }}
          {{
            transaction.product_variant_id
              ? ` (${transaction.product_variant.name})`
              : ""
          }}
        </td>
        <td>
          {{ transaction.product_variant.sku }}
        </td>
        <td>
          <span v-if="!transaction.edit_mode">{{ transaction.quantity }}</span>
          <input
            v-else
            type="number"
            v-model="transaction.new_quantity"
            class="input"
          />
        </td>
        <td v-if="showControl == true" style="text-align: right;">
          <div v-if="!transaction.edit_mode">
            <a @click="transaction.edit_mode = true">{{ editLabel }}</a>
            <br />
            <a @click="confirmDelete(index)" class="delete">{{
              deleteLabel
            }}</a>
          </div>
          <div v-else>
            <a
              @click="updateInventoryTransaction(index)"
              class="btn btn-primary"
              >{{ saveLabel }}</a
            >
          </div>
        </td>
      </tr>
    </table>

    <div class="flex-pagination-wrapper">
      <div v-if="inventory_transactions.length != 0 && locale == 'en'" class="dataTables_info">
        Showing {{ paginate.from }} to {{ paginate.to }} of
        {{ paginate.count }} entries
      </div>
      <div v-if="inventory_transactions.length != 0 && locale == 'cn'" class="dataTables_info">
        总共有 {{ paginate.count }} 条库存记录
      </div>

      <!-- table pagination -->
      <nav
        v-if="inventory_transactions.length != 0"
        aria-label="pager"
        role="navigation"
        class="pagy-bootstrap-nav"
      >
        <ul class="pagination">
          <li class="page-item prev">
            <a
              @click="getInventoryTransaction(paginate.prev)"
              aria-label="previous"
              class="page-link"
            >
              <
            </a>
          </li>
          <li
            v-for="index in paginate.menu"
            :key="index"
            v-show="index != '...'"
            :class="{ active: index == paginate.page }"
            class="page-item"
          >
            <a @click="getInventoryTransaction(index)" class="page-link">{{
              index
            }}</a>
          </li>
          <li class="page-item next">
            <a
              @click="getInventoryTransaction(paginate.next)"
              rel="next"
              aria-label="next"
              class="page-link"
            >
              >
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'placeholderImage',
    'showControl',
    "branchId",
    "token",
    "locale",
    "dateLabel",
    "remarkLabel",
    "quantityLabel",
    "skuLabel",
    "editLabel",
    "saveLabel",
    "deleteLabel",
    "emptyMessage",
    "deletedMessage",
    "updatedMessage",
  ],
  data() {
    return {
      inventory_transactions: [],
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      paginate: {},
    };
  },
  mounted() {
    this.getInventoryTransaction(1);
  },
  methods: {
    getInventoryTransaction(page) {
      const vm = this;
      this.$http
        .get(
          `/api/v1/branches/${this.branchId}/inventory_transactions?page=${page}`
        )
        .then((response) => {
          vm.inventory_transactions = response.data.data.map((transaction) => {
            return {
              ...transaction,
              ...{
                new_quantity: transaction.quantity,
                edit_mode: false,
                created_at: new Date(transaction.created_at),
                updated_at: new Date(transaction.updated_at),
              },
            };
          });
          const pagy = response.data.pagy;
          vm.paginate = {
            per_page: pagy.vars.items,
            page: pagy.page,
            prev: pagy.prev,
            next: pagy.next,
            pages: pagy.pages,
            from: pagy.from,
            to: pagy.to,
            count: pagy.count,
            menu: vm.pagination(pagy.page, pagy.pages),
          };
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateInventoryTransaction(index) {
      const vm = this;
      const transaction = this.inventory_transactions[index];
      this.$http
        .put(
          `/api/v1/branches/${this.branchId}/inventory_transactions/${transaction.id}`,
          {
            authenticity_token: this.token,
            inventory_transaction: {
              quantity: transaction.new_quantity,
            },
          }
        )
        .then((response) => {
          vm.inventory_transactions[index].quantity = transaction.new_quantity;
          vm.inventory_transactions[index].edit_mode = false;
          vm.$toast.open({
            message: vm.updatedMessage,
            type: "success",
            position: "top-right",
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    confirmDelete(index) {
      const label =
        this.locale == "cn"
          ? "确定要删除此库存记录"
          : "Are you sure want to delete?";
      const r = confirm(label);
      if (r == true) {
        this.deleteTransaction(index);
      }
    },
    deleteTransaction(index) {
      const vm = this;
      const transaction = this.inventory_transactions[index];
      // console.log(this.token);
      this.$http
        .delete(
          `/api/v1/branches/${this.branchId}/inventory_transactions/${transaction.id}`,
          {
            params: {
              authenticity_token: this.token,
            },
          }
        )
        .then((response) => {
          vm.$toast.open({
            message: vm.deletedMessage,
            type: "success",
            position: "top-right",
          });
          vm.getInventoryTransaction(1);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    pagination(c, m) {
      var current = c,
        last = m,
        delta = 1,
        left = current - delta,
        right = current + delta + 1,
        range = [],
        rangeWithDots = [],
        l;

      for (let i = 1; i <= last; i++) {
        if (i == 1 || i == last || (i >= left && i < right)) {
          range.push(i);
        }
      }

      for (let i of range) {
        if (l) {
          if (i - l === 2) {
            rangeWithDots.push(l + 1);
          } else if (i - l !== 1) {
            rangeWithDots.push("...");
          }
        }
        rangeWithDots.push(i);
        l = i;
      }

      return rangeWithDots;
    },
  },
};
</script>

<style scoped>
.empty-message {
  margin: 1.5rem 0;
}
table {
  width: calc(100% + 4rem);
  transform: translateX(-2rem);
  box-shadow: none;
  /* margin: 1.5rem 0; */
  border-bottom: 1px solid #e5e5e5;
}
th {
  padding: 1rem !important;
  background: #e5e5e5;
}
td {
  padding: 1rem !important;
  border-bottom: 0;
}
tr th:first-child,
tr td:first-child {
  padding-left: 2rem !important;
}
tr th:last-child,
tr td:last-child {
  padding-right: 2rem !important;
}
tr:hover td {
  background: #f5f5f5;
}
.thumb {
  width: 40px;
  margin-right: 1rem;
  display: inline-block;
}
.delete {
  color: red;
}
.input {
  width: 80px;
}
</style>
