<template>
  <div class="product-detail">
    <div class="card">
      <div class="card-header flex-header">
        <h2 class="primary-title">{{directpayLabel}}</h2> 
        <!-- <locale-picker :input="locale" :url="path" layout="no-margin"></locale-picker> -->
      </div>
      <div class="direct-pay-row">
        <div class="qr-code">
          <qrcode
            :value="
              direct_payment != ''
                ? JSON.stringify(qrcode_value)
                : 'https://xpayplaceoder.com'
            "
          ></qrcode>
          <div v-if="direct_payment == ''" class="overlay"></div>
          <div v-if="direct_payment == ''" class="overlay-placeholder">
            {{ QrPlaceholder }}
          </div>
          <div class="payment-status"></div>
        </div>
        <form
          @submit="createDirectPayment"
          class="form"
          :class="{ readonly: direct_payment != '' }"
        >
          <div class="form-group">
            <div class="title">{{ currencyLabel }}</div>
            <currency-picker
              input-name="direct_pay[wallet]"
              :input-value="amount_currency"
              @newCurrency="amount_currency = $event"
            ></currency-picker>
          </div>
          <div class="form-group">
            <div class="title">{{ amountLabel }}</div>
            <input
              type="number"
              name="direct_pay[amount]"
              min="0.00000001"
              step="0.00000001"
              class="form-control"
              v-model="amount"
            />
          </div>
          <div class="form-group">
            <div class="title">{{ remarkLabel }}</div>
            <input
              type="text"
              name="direct_pay[remark]"
              class="form-control"
              v-model="remark"
            />
          </div>
          <div class="control">
            <button
              v-if="direct_payment == ''"
              type="submit"
              class="btn btn-large btn-primary"
            >
              {{ generateQrLabel }}
            </button>
            <div
              v-if="direct_payment != ''"
              type="button"
              class="btn btn-large btn-primary"
              @click="direct_payment = ''"
            >
              {{ cancelLabel }}
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="nav-wrapper">
      <ul class="nav">
        <li
          @click="type = 'completed'"
          class="btn"
          :class="{ active: type == 'completed' }"
        >
          {{ completedLabel }}
        </li>
        <li
          @click="type = 'pending'"
          class="btn"
          :class="{ active: type == 'pending' }"
        >
          {{ pendingLabel }}
        </li>
      </ul>
      <a :href="'/'+locale+'/direct_pay_report'">{{ locale == 'en' ? 'View report' : '查看报告'}}</a>
    </div>
    <div
      v-show="type == 'completed'"
      class="card-shadow table-responsive"
      style="margin: 1.5rem 0 3rem; box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);"
    >
      <table>
        <thead>
          <tr>
            <th>{{ transactLabel }}</th>
            <th>{{ amountLabel }}</th>
            <th>{{ currencyLabel }}</th>
            <th>{{ createdbyLabel }}</th>
            <th>{{ remarkLabel }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(payment, index) in complete_direct_payments" :key="index">
            <td class="text-primary">
              <b v-if="locale == 'cn'">
                {{new Date(payment.created_at).getFullYear()}}年{{new Date(payment.created_at).getMonth() + 1 }}月{{new Date(payment.created_at).getDate()}}日 {{new Date(payment.created_at).getHours()}}:{{new Date(payment.created_at).getMinutes() < 10 ? `0${new Date(payment.created_at).getMinutes()}` : new Date(payment.created_at).getMinutes()}}
              </b>
              <b v-else>
                {{new Date(payment.created_at).getDate()}} {{months[new Date(payment.created_at).getMonth()]}}, {{new Date(payment.created_at).getFullYear()}} {{new Date(payment.created_at).getHours()}}:{{new Date(payment.created_at).getMinutes() < 10 ? `0${new Date(payment.created_at).getMinutes()}` : new Date(payment.created_at).getMinutes()}}
              </b>
            </td>
            <td>{{ Number(payment.amount_satoshi / 100000000).toFixed(8) }}</td>
            <td>{{ payment.amount_currency }}</td>
            <td>{{ payment.merchant.first_name }} {{ payment.merchant.last_name }}</td>
            <td>{{ payment.notes }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      v-show="type == 'pending'"
      class="card-shadow table-responsive"
      style="margin: 1.5rem 0 3rem; box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);"
    >
      <table>
        <thead>
          <tr>
            <th>{{ transactLabel }}</th>
            <th>{{ amountLabel }}</th>
            <th>{{ currencyLabel }}</th>
            <th>{{ createdbyLabel }}</th>
            <th>{{ remarkLabel }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(payment, index) in pending_direct_payments" :key="index">
            <td class="text-primary">
              <b v-if="locale == 'cn'">
              {{new Date(payment.created_at).getFullYear()}}年{{new Date(payment.created_at).getMonth() + 1}}月{{new Date(payment.created_at).getDate()}}日 {{new Date(payment.created_at).getHours()}}:{{new Date(payment.created_at).getMinutes() < 10 ? `0${new Date(payment.created_at).getMinutes()}` : new Date(payment.created_at).getMinutes()}}
              </b>
              <b v-else>
                {{new Date(payment.created_at).getDate()}} {{months[new Date(payment.created_at).getMonth()]}}, {{new Date(payment.created_at).getFullYear()}} {{new Date(payment.created_at).getHours()}}:{{new Date(payment.created_at).getMinutes() < 10 ? `0${new Date(payment.created_at).getMinutes()}` : new Date(payment.created_at).getMinutes()}}
              </b>
            </td>
            <td>{{ Number(payment.amount_satoshi / 100000000).toFixed(8) }}</td>
            <td>{{ payment.amount_currency }}</td>
            <td>{{ payment.merchant.first_name }} {{ payment.merchant.last_name }}</td>
            <td>{{ payment.notes }}</td>
            <td>
              <div class="btn btn-primary" @click="selectPayment(payment)">
                {{ paynowLabel }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "token",
    "directpayLabel",
    "path",
    "QrPlaceholder",
    "generateQrLabel",
    "walletLabel",
    "amountLabel",
    "remarkLabel",
    "transactLabel",
    "currencyLabel",
    "cancelLabel",
    "completedLabel",
    "pendingLabel",
    "createdbyLabel",
    "paynowLabel",
    "paymentSuccessLabel",
    "locale"
  ],
  data() {
    return {
      type: "completed",
      direct_payment: "",
      direct_payment_url: "",
      payme: "",
      qrcode_value: {},
      pending_direct_payments: [],
      complete_direct_payments: [],
      pagination: {},
      pagination_menu: [],
      page: 1,
      amount_currency: null,
      amount: null,
      remark: null,
      expire_time: null,
      payment_success: false,
      months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ],
    };
  },
  computed: {},
  watch: {
    direct_payment: function(dp) {
      if (dp != "") {
        $("body")
          .find("select")
          .prop("readonly", true);
        $("input").prop("readonly", true);
        this.loop();
      } else {
        $("body")
          .find("select")
          .prop("readonly", false);
        $("input").prop("readonly", false);
      }
    },
    expire_time: function(time) {
      let expired_at = new Date(time * 1000);
      let vm = this;
      var x = setInterval(function() {
        var now = new Date().getTime();
        var distance = expired_at - now;

        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // Display the result in the element with id="demo"
        $(".qr-code")
          .find("#timer")
          .html( (vm.locale == 'en' ? 'Expire at ' : '截止 ') + minutes + "m " + seconds + "s ");

        // If the count down is finished, write some text
        if (distance < 0) {
          clearInterval(x);
          window.location.reload();
        }
      }, 1000);
    },
  },
  mounted() {
    this.getPenidngDirectPayments();
    this.getCompleteDirectPayments();
  },
  methods: {
    getPenidngDirectPayments(e) {
      let vm = this;
      let api = "/api/v1/direct_payments?type=PENDING";
      api += this.page ? "&page=" + this.page : "";
      this.$http
        .get(api)
        .then((response) => {
          vm.pending_direct_payments = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCompleteDirectPayments(e) {
      let vm = this;
      let api = "/api/v1/direct_payments?";
      api += this.page ? "&page=" + this.page : "";
      this.$http
        .get(api)
        .then((response) => {
          vm.complete_direct_payments = response.data.data;
          console.log(vm.complete_direct_payments[0]);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createDirectPayment(e) {
      e.preventDefault();
      let vm = this;
      let formData = {
        authenticity_token: this.token,
        direct_payment: {
          amount_currency: this.amount_currency,
          amount: this.amount,
          notes: this.remark,
        },
      };
      let api = "/api/v1/direct_payments";
      this.$http
        .post(api, formData)
        .then((response) => {
          let direct_payment = response.data.data;
          vm.direct_payment = response.data.data.id;
          vm.payme = direct_payment.payme_transaction.payme_code;
          vm.qrcode_value = {
            payment: {
              address: vm.payme,
              symbol: vm.amount_currency,
              notes: direct_payment.notes,
              amount: Number(
                direct_payment.amount_satoshi / 100000000
              ).toFixed(8),
            },
          };
          vm.payment_success = false;
          $('.payment-status').html('');
          vm.getPenidngDirectPayments();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loop: function() {
      this.intervalid1 = setInterval(
        function() {
          this.getPeymeDetail();
        }.bind(this),
        1000
      );
    },
    getPeymeDetail() {
      let vm = this;
      if (!this.payment_success) {
        this.$http
          .get(this.$apiURL + "/coin/payme/" + this.payme)
          .then((response) => {
            if (!vm.expire_time) {
              vm.expire_time = response.data.expiry_timestamp;
              $(".qr-code").append(
                '<div id="timer" style="text-align: center; margin: 1rem auto; font-weight: bold; margin-right: 1rem;"></div>'
              );
            }

            if (response.data.complete) {
              vm.payment_success = true;
              $(".qr-code")
                .find("#timer")
                .remove();
              vm.checkPayment();
              $('.payment-status').html(vm.locale == 'en' ? 'Successful payment!' : '成功付款!');
              setTimeout(function(){ window.location.reload() }, 1000);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    checkPayment() {
      this.$http
        .get("/api/v1/direct_payments/" + this.direct_payment + "/check")
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    },
    selectPayment(payment) {
      let vm = this;
      this.$http
        .get("/api/v1/direct_payments/" + payment.id)
        .then((response) => {
          vm.payment_success = false;
          $('.payment-status').html('');

          let direct_payment = response.data.data;
          vm.direct_payment = direct_payment.id;
          vm.amount_currency = direct_payment.amount_currency;
          vm.remark = direct_payment.notes;
          vm.amount = Number(direct_payment.amount_satoshi / 100000000).toFixed(8);
          vm.payme = direct_payment.payme_transaction.payme_code;
          vm.qrcode_value = {
            payment: {
              address: direct_payment.payme_transaction.payme_code, // payme code
              symbol: direct_payment.amount_currency,
              notes: direct_payment.notes,
              amount: Number(direct_payment.amount_satoshi / 100000000).toFixed(8),
            },
          };
        })
        .catch((error) => {
          console.log(error);
      });
    },
  },
};
</script>
<style>
.readonly select {
  background: #e5e5e5;
}
</style>

<style scoped>
.readonly input {
  background: #e5e5e5;
}
.nav {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 2rem 0 1rem;
}
.nav li {
  margin-right: 1rem;
  padding: 1rem;
  width: auto;
  background: #fff;
  color: #f9b73e;
}
.nav li.active {
  background-color: #f9b73e;
  color: #fff;
}
.payment-status {
  font-weight: bold;
  text-align: center;
  color: #3edc3e;
  font-size: 20px;
  margin-bottom: 10px;
}
.nav-wrapper {
  position: relative;
}
.nav-wrapper a {
  position: absolute;
  right: 0;
  top: 1.5rem;
}
</style>
