<template>
  <div style="padding-top: 1rem;">
    <div class="form-group">
      <div class="title">{{selectPeriodLabel}}</div>
      <select name="store[amyr_auto_withdrawal_period]" class="form-control" v-model="selected_period">
        <option value="NONE">{{ noneLabel }}</option>
        <option value="WEEKLY">{{ weeklyLabel }}</option>
        <option value="MONTHLY">{{ monthlyLabel }}</option>
      </select>
      <p v-if="selected_period != 'NONE'" class="text-danger">
        <span>
          MDR: {{ selected_period == 'WEEKLY' ? weeklyFee : monthlyFee}}
        </span>
      </p>
    </div>
    <p>{{ messageLabel }}</p>
    <div @click="updateSetting" class="btn btn-primary btn-block" style="padding: 1rem;">{{submitLabel}}</div>
  </div>
</template>

<script>
  export default {
    props: [
      'token', 
      'storeId',
      'selectPeriodLabel',
      'noneLabel',
      'weeklyLabel',
      'weeklyFee',
      'monthlyLabel',
      'monthlyFee',
      'submitLabel',
      'messageLabel',
      'defaultSetting',
      'settingUpdatedMessage',
      'somethingWrongMessage',
      'locale'
    ],
    data() {
      return {
        selected_period: '',
        error_message: '',
      }
    },
    mounted() {
      this.selected_period = this.defaultSetting
    },
    methods: {
      updateSetting() {
        let vm = this
        this.$http
          .put(`/api/v1/stores/${vm.storeId}`, {
            authenticity_token: vm.token,
            store:{
              amyr_auto_withdrawal_period: this.selected_period,
            }
          })
          .then(() => {
            vm.$toast.open({
              message: vm.settingUpdatedMessage,
              type: "success",
              position: "top-right",
            });

              var element = document.getElementById("modal-autoWithdrawal");
              element.classList.remove("active");
          })
          .catch((error) => {
            vm.$toast.open({
              message: vm.somethingWrongMessage,
              type: "error",
              position: "top-right",
            });
            console.log(error);
          });
      }
    }
  }
</script>

<style scoped>
.text-danger {
  margin-bottom: .5rem;
}
</style>