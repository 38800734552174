<template>
  <a class="modal-toggle btn btn-primary" @click="reject">{{ yesLabel }}</a>
</template>

<script>
export default {
  props: [
    "token",
    "storeId",
    "yesLabel",
    "rejectedSuccessful",
    "somethingWrongMessaeg"
  ],
  methods: {
    reject() {
      let vm = this;
      this.$http
        .put(`/api/v1/admin/stores/${this.storeId}/reject`, {
          authenticity_token: this.token,
        })
        .then(() => {
          vm.$toast.open({
            message: vm.rejectedSuccessful,
            type: "success",
            position: "top-right",
          });
          setTimeout(function(){ window.location.reload(); }, 1000);
        })
        .catch((error) => {
          this.isLoading = false;
          vm.$toast.open({
            message: vm.somethingWrongMessaeg,
            type: "error",
            position: "top-right",
          });
          console.log(error);
        });
    },
  },
};
</script>