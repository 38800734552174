<template>

  <form :action="'/'+locale+'/user'" method="post">
    <div class="card vendor-form">
      <input type="hidden" name="_method" value="put">
      <input type="hidden" name="authenticity_token" :value="authenticityToken">
      <div class="form-group">
        <div class="title">{{firstNameLabel}}</div>
        <input class="form-control" :placeholder="firstNameLabel" v-model="first_name" name="user[first_name]">
      </div>
      <div class="form-group">
        <div class="title">{{lastNameLabel}}</div>
        <input class="form-control" :placeholder="lastNameLabel" v-model="last_name" name="user[last_name]">
      </div>
      <div class="form-group">
        <div class="title">{{emailLabel}}</div>
        <input type="email" class="form-control" :placeholder="emailLabel" v-model="email" name="user[email]" :data-error-message="emailError">
      </div>
      <div class="form-group">
        <div class="title">{{phoneLabel}}</div>
        <input type="text" class="form-control" :placeholder="phoneLabel" v-model="phone" name="user[phone_number]">
      </div>
      <div class="form-group">
        <div class="title">{{passwordLabel}}</div>
        <input type="password" class="form-control" :placeholder="passwordLabel" :name="(password != '' ? 'user[password]' : '')" v-model="password">
      </div>
    </div>
    <input type="submit" class="btn btn-large btn-block btn-primary" :value="saveLabel">
  </form>
</template>

<script>
  export default {
    props: [
      'user', 
      'authenticityToken',
      'firstNameLabel',
      'lastNameLabel',
      'emailLabel',
      'phoneLabel',
      'passwordLabel',
      'saveLabel',
      'emailError',
      'locale'
    ],
    data() {
      return {
        first_name: null,
        last_name: null,
        phone: null,
        email: null,
        password: '',
      }
    },
    computed: {},
    watch: {},
    mounted() {
      if(typeof this.user !== 'undefined') {
        this.email = this.user.email;
        this.phone = this.user.phone_number;
        this.first_name = this.user.first_name;
        this.last_name = this.user.last_name;
      }
    },
    methods: {},
  }
</script>
