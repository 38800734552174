<template>
  <form :action="'/'+locale+'/store'" method="post" enctype="multipart/form-data">
    <input type="hidden" name="_method" value="put">
    <input type="hidden" name="authenticity_token" :value="authenticityToken">
    <div class="form-group full profile-picture-card">
      <div class="product-picture-input">
        <input type="file" id="profile-picture" name="store[logo]" @change="selectProfilePicture">
        <div class="profile-picture-preview">
          <img v-if="profile_picture != null" :src="profile_picture">
          <svg v-else width="34" height="35" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.7487 13.6917C21.168 15.2512 19.2339 16.0417 17 16.0417C14.7666 16.0417 12.8325 15.251 11.2515 13.692C9.67102 12.1327 8.86957 10.2247 8.86957 8.02058C8.86957 5.817 9.67102 3.90891 11.2515 2.34967C12.832 0.790421 14.7659 0 17 0C19.2336 0 21.1677 0.790421 22.7485 2.34941C24.3292 3.90917 25.1304 5.81726 25.1304 8.02058C25.1304 10.2247 24.329 12.1325 22.7487 13.6917Z" fill="white"/>
            <path d="M33.9176 26.5764C33.8626 25.8659 33.7513 25.091 33.5874 24.2727C33.422 23.4482 33.209 22.6688 32.9541 21.9565C32.6904 21.2202 32.3326 20.4931 31.8895 19.7963C31.4303 19.0731 30.8905 18.4434 30.2849 17.9252C29.6515 17.3831 28.8761 16.9472 27.9794 16.6293C27.0858 16.313 26.0956 16.1528 25.0363 16.1528C24.6203 16.1528 24.2179 16.3055 23.441 16.758C22.9628 17.037 22.4034 17.3597 21.7791 17.7166C21.2453 18.0209 20.5221 18.306 19.6288 18.5641C18.7573 18.8164 17.8724 18.9443 16.9988 18.9443C16.1257 18.9443 15.2408 18.8164 14.3687 18.5641C13.4764 18.3063 12.7529 18.0211 12.22 17.7168C11.6016 17.3633 11.0419 17.0407 10.5566 16.7578C9.7802 16.3052 9.37787 16.1525 8.96187 16.1525C7.90226 16.1525 6.9123 16.313 6.01904 16.6295C5.12298 16.9469 4.34724 17.3828 3.71328 17.9255C3.10761 18.4439 2.56786 19.0734 2.10895 19.7963C1.66651 20.4931 1.30834 21.2199 1.04468 21.9568C0.79004 22.6691 0.577062 23.4482 0.411654 24.2727C0.24749 25.0899 0.136493 25.8651 0.0814603 26.5772C0.0273607 27.2734 0 27.998 0 28.7301C0 30.6332 0.676244 32.1739 2.00977 33.3102C3.32681 34.4315 5.06919 35 7.18871 35H26.8113C28.9302 35 30.6726 34.4315 31.9899 33.3102C33.3238 32.1748 34 30.6335 34 28.7298C33.9997 27.9952 33.972 27.2706 33.9176 26.5764Z" fill="white"/>
          </svg>
        </div>
        <label for="profile-picture">
          <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.06655 0.991677L6.06671 4.36364L9.43357 4.36891C9.50054 4.36833 9.5669 4.38166 9.62846 4.40805C9.69002 4.43443 9.74544 4.4733 9.79121 4.5222C9.87808 4.62109 9.92599 4.74822 9.92599 4.87984C9.926 5.01146 9.8781 5.13858 9.79124 5.23747C9.74604 5.28359 9.69194 5.32005 9.63222 5.34464C9.5725 5.36923 9.50841 5.38143 9.44383 5.3805L6.05654 5.38545L6.0516 8.77274C6.05365 8.90353 6.00154 9.02921 5.90856 9.12014C5.80968 9.207 5.68256 9.2549 5.55094 9.2549C5.41931 9.25489 5.29219 9.20698 5.1933 9.12011C5.1444 9.07434 5.10553 9.01892 5.07914 8.95736C5.05276 8.8958 5.03943 8.82944 5.04001 8.76247L5.03474 5.39561L1.67299 5.39545C1.60602 5.39603 1.53966 5.3827 1.4781 5.35632C1.41654 5.32993 1.36112 5.29106 1.31535 5.24216C1.22566 5.14262 1.17602 5.01339 1.17602 4.87941C1.17601 4.74544 1.22564 4.61621 1.31532 4.51668C1.36052 4.47055 1.41462 4.43409 1.47434 4.4095C1.53406 4.38492 1.59815 4.37272 1.66272 4.37364L5.04491 4.37381L5.04474 0.981409C5.04516 0.917324 5.0585 0.853984 5.08396 0.795171C5.10941 0.736359 5.14646 0.683282 5.19289 0.639111C5.29177 0.552249 5.41889 0.50435 5.55051 0.504356C5.68213 0.504363 5.80926 0.552275 5.90815 0.639146C5.95735 0.683881 5.9968 0.73827 6.02405 0.798923C6.0513 0.859575 6.06577 0.92519 6.06655 0.991677Z" fill="#ECBC7B"/>
          </svg>
        </label>
      </div>
      <div class="caption-section">
        <div class="title">{{noteLabel}}</div> 
        <div class="caption">{{noteDescription}}</div> 
        <label for="profile-picture" class="btn btn-primary">{{uploadLabel}}</label>
      </div>
    </div>
    <div class="card vendor-form">
      <div class="form-group full">
        <div class="title">{{shopNameLabel}}</div>
        <input type="text" class="form-control" :placeholder="shopNameLabel" name="store[name]" v-model="store_name">
      </div>
      <div class="form-group full">
        <div class="title">{{shopDescriptionLabel}}</div>
        <textarea rows="6" class="form-control" :placeholder="shopDescriptionLabel" name="store[description]" v-model="description"></textarea>
      </div>
    </div>
    <input type="submit" class="btn btn-large btn-block btn-primary" :value="saveLabel">
  </form>
</template>

<script>
  export default {
    props: [
      'authenticityToken', 
      'store', 
      'category',
      'noteLabel',
      'noteDescription',
      'uploadLabel',
      'shopNameLabel',
      'shopDescriptionLabel',
      'saveLabel',
      'locale'
    ],
    data() {
      return {
        store_name: null,
        description: null,
        profile_picture: null,
        store_profile_picture: null,
      }
    },
    computed: {},
    watch: {},
    mounted() {
      if(typeof this.store !== 'undefined') {
        this.store_name = this.store.name;
        this.store_phone_number = this.store.phone_number;
        this.lot_number = this.store.lot_number;
        this.address = this.store.address;
        this.description = this.store.description;
        this.is_halal = this.store.is_halal;
        this.store_profile_picture = this.store.logo ? this.store.logo.url : null;
        this.profile_picture = this.store_profile_picture;
        this.estimated_delivery_time = this.store.estimated_delivery_time;
      }
    },
    methods: {
      selectProfilePicture(e) {
        const file = e.target.files[0];
        this.profile_picture = file ? URL.createObjectURL(file) : (this.store_profile_picture ? this.store_profile_picture : null);
      }
    },
  }
</script>
