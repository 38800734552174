<template>
<div class="cart-menu">
  <a :href="`/${locale}/cart`">
    <div class="icon">
      <svg id="Layer_1" enable-background="new 0 0 512 512" height="18" viewBox="0 0 512 512" width="18" xmlns="http://www.w3.org/2000/svg">
        <g><path d="m503.99 105.37c-6.86-8.5-17.07-13.37-28-13.37h-390.253l-3.485-24.976c-2.468-17.686-17.796-31.024-35.655-31.024h-30.597c-8.836 0-16 7.163-16 16s7.164 16 16 16h30.597c1.984 0 3.688 1.482 3.961 3.447l5.39 38.629c.006.045.005.089.011.134l31.26 224c.003.019.008.038.011.057l6.517 46.708c2.469 17.687 17.797 31.025 35.656 31.025h17.349c-1.776 5.008-2.752 10.391-2.752 16 0 26.467 21.533 48 48 48s48-21.533 48-48c0-5.609-.976-10.992-2.752-16h85.504c-1.776 5.008-2.752 10.391-2.752 16 0 26.467 21.533 48 48 48s48-21.533 48-48c0-5.609-.976-10.992-2.752-16h34.752c8.836 0 16-7.164 16-16s-7.164-16-16-16h-318.597c-1.984 0-3.688-1.482-3.961-3.447l-3.985-28.553h315.103c16.86 0 31.66-11.97 35.21-28.46l39.42-183.99c2.29-10.69-.33-21.68-7.2-30.18zm-24.301 37.767-32 160c-1.521 7.606-8.202 12.865-15.672 12.865-1.041 0-2.096-.102-3.155-.313-8.665-1.733-14.285-10.162-12.552-18.827l32-160c1.733-8.666 10.163-14.287 18.827-12.552 8.666 1.734 14.285 10.162 12.552 18.827z"/></g>
      </svg> 
    </div>
    <span class="cart-count">{{ cart_item_count }}</span>
    <span class="label">{{ btnLabel }}</span>
  </a>
</div>
</template>


<script>
  export default {
    props: [
      'locale', 
      'btnLabel', 
    ],
    data() {
      return {
        cart_item_count: 0,
      }
    },
    mounted() {
      this.updateCartCount()
    },
    methods: {
      updateCartCount(){
        let vm = this
        this.$http.get('/api/v1/cart_items/count')
        .then(response => {
          vm.cart_item_count = response.data.data
        })
        .catch(error => {
          console.log(error);
        });
      }
    },
  }
</script>