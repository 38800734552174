<template>
  <div class="switch-wrapper">
    <md-switch @change="changeInventoryStatus" v-model="foundInventory" :disabled="currentMerchant.branch_id !== branch.id && currentMerchant.role !== 'BOSS'">{{ branch.name }}</md-switch>
  </div>
</template>

<script>
export default {
  props: [
    "branch",
    "productId",
    "currentMerchant",
    "productVariant",
    "token",
    "locale",
    "enabledSuccessMessage",
    "disabledSuccessMessage",
  ],
  data() {
    return {
      foundInventory: false,
      inventories: [],
      enabledLabel: '',
      disabledLabel: ''
    };
  },
  mounted() {
    this.getBranchInventory()
  },
  methods: {
    getBranchInventory() {
      const vm = this;
      this.$http
        .get(`/api/v1/branches/${this.branch.id}/branch_inventories?items=999&product_id=${this.productId}`)
        .then((response) => {
          vm.inventories = response.data.data;
          vm.checkInventory()
        })
        .catch((error) => {
          console.log(error);
        });
    },
    changeInventoryStatus() {
      // mark as active / create new transaction record
      let vm = this;
      if(this.foundInventory) {
        // no variant
        this.inventories.forEach(function(inventory){
          vm.updateBranchInvetory(inventory.id, true)
        });

        if(this.productVariant.length == 0) {
          this.addTransaction('');
        }
        else {
          this.productVariant.forEach(function(variant) {
            vm.addTransaction(variant.id)
          })
        }

        this.$toast.open({
          message: this.enabledSuccessMessage,
          type: "success",
          position: "top-right",
        });
      }
      // disable active
      else {
        this.inventories.forEach(function(inventory){
          vm.updateBranchInvetory(inventory.id, false)
        });

        this.$toast.open({
          message: this.disabledSuccessMessage,
          type: "success",
          position: "top-right",
        });
      }

      window.location.replace(`/${this.locale}/products/${this.productId}/edit?page=product-availability&branch_id=${this.branch.id}`)
    },
    updateBranchInvetory(id, status) {
      const vm = this;
      this.$http
        .put(`/api/v1/branches/${this.branch.id}/branch_inventories/${id}`, {
          authenticity_token: this.token,
          branch_inventory: {
            active: status,
          },
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addTransaction(variant_id) {
      const vm = this;
      this.$http
        .post(`/api/v1/branches/${this.branch.id}/inventory_transactions`, {
          authenticity_token: this.token,
          inventory_transaction: {
            product_id: this.productId,
            product_variant_id: variant_id,
            quantity: 0,
          },
        })
        .catch((error) => {
          console.log(error);
        });
    },
    checkInventory() {
      const vm = this;
      let activeFound = false;
      this.inventories.forEach(function(v) {
        if(v.active) {
          activeFound = true
        }
      });
      if(activeFound) {
        this.foundInventory = true
      }
    }
  },
};
</script>

<style scoped>
.empty-message {
  margin: 1.5rem 0;
}
table {
  width: calc(100% + 4rem);
  transform: translateX(-2rem);
  box-shadow: none;
  border-bottom: 1px solid #e5e5e5;
}
th {
  padding: 1rem !important;
  background: #e5e5e5;
}
td {
  padding: 1rem !important;
  border-bottom: 0;
}
tr th:first-child,
tr td:first-child {
  padding-left: 2rem !important;
}
tr th:last-child,
tr td:last-child {
  padding-right: 2rem !important;
}
tr:hover td {
  background: #f5f5f5;
}
.thumb {
  width: 40px;
  margin-right: 1rem;
  display: inline-block;
}
.move-to {
  font-weight: bold;
  display: inline-block;
  margin: 0 0.5rem;
}
.new_inventory {
  width: 50px;
}
.minus {
  color: red;
}
.add {
  color: rgb(26, 185, 26);
}
.md-switch {
  margin: 0;
}
.switch-wrapper {
  margin-right: 15px;
}
</style>
