<template>
  <div style="padding-top: 1rem;">
    <div class="form-group">
      <div class="title">{{selectPeriodLabel}}</div>
      <input type="text" class="form-control" :value="oneTimeLabel" disabled="true">
      <p class="text-danger">
        <span>
          MDR: {{ fee }}
        </span>
      </p>
    </div>
    <div class="form-group">
      <div class="title">{{amountToWithdrawLabel}}</div>
      <input type="number" class="form-control" v-model="amount">
    </div>
    <div class="transfer-amount">
        <div>{{ amountToTransferLabel }}</div>
        <div>RM {{ wallet_amount * rate }}</div>
    </div>
    <p>{{ messageLabel }}</p>
    <p v-if="error_message" class="text-danger" style="margin-bottom: 1.5rem;">{{error_message}}</p>
    <div @click="withdraw" class="btn btn-primary btn-block" style="padding: 1rem;">{{submitLabel}}</div>
  </div>
</template>

<script>
  export default {
    props: [
      'token', 
      'amyrWallet',
      'selectPeriodLabel',
      'oneTimeLabel',
      'rate',
      'fee',
      'amountToWithdrawLabel',
      'amountToTransferLabel',
      'submitLabel',
      'messageLabel',
      'locale'
    ],
    data() {
      return {
        amount: 0,
        wallet_amount: 0,
        error_message: '',
      }
    },
    mounted() {
      this.getWalletDetail();
    },
    methods: {
      getWalletDetail() {
        let vm = this;
        this.$http.get(this.$apiURL+'/coin/'+this.amyrWallet.address+'?pagesize=10&pageindex=1')
        .then(response => {
          if(response.data.account) {
            vm.wallet_amount = response.data.account.amount;
          }
        })
        .catch(error => {
          console.log(error);
        });
      },
      validation() {
        var error_found = false;
        if(!this.amount && this.amount == '' || this.amount <= 0) {
          error_found = true;
          this.$toast.open({
            message: this.locale == 'en' ? 'Withdraw amount should not be empty & should not be lower then 0' : '提款金额不能为空且不能小于0',
            type: 'error',
            position: 'top-right'
          });
        }
        return !error_found;
      },
      withdraw() {
        if(this.validation()) {
          let vm = this;
          let formData = {
            withdrawal_request: {
              wallet_id: this.amyrWallet.id,
              crypto_amount: this.amount
            },
            authenticity_token: this.token
          };
          if(!this.converting && !this.withdrawing) {
            vm.converting = true;
            // to do fix 
            this.$http.post('/api/v1/withdrawal_requests', formData)
            .then(response => {
              vm.$toast.open({
                message: vm.locale == 'en' ? 'Withdraw request submitted successful' : '提款申请成功',
                type: 'success',
                position: 'top-right'
              });
              window.location.reload();
            })
            .catch(error => {
              vm.converting = false;
              vm.$toast.open({
                message: vm.locale == 'en' ? 'Something wrong' : '不好意思，系统出问题',
              type: 'error',
                position: 'top-right'
              });
              console.log(error);
            });
          }
        }
      }
    }
  }
</script>

<style scoped>
.text-danger {
  margin-bottom: .5rem;
}
.transfer-amount {
    background: #FFF2D9;
    border-radius: 4px;
    padding: 16px 20px;
    display: flex;
    justify-content: space-between;
}
</style>