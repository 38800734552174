<template>
  <div>
    <div class="d-flex">
      <div class="form-group">
        <div class="title"> {{ skuLabel }}</div>
        <v-select
          label="sku"
          :options='productListBySku'
        >
          <template slot="no-options">
            {{ typeProductPlaceholder }}
          </template>
        </v-select>
      </div>
      <div class="form-group">
        <div class="title">Variant {{ skuLabel }}</div>
        <v-select
          label="sku"
          :options="productVariantListBySku"
          v-model="selectedVariantSku"
        >
          <template slot="no-options">
            {{ typeProductPlaceholder }}
          </template>
        </v-select>
      </div>
    </div>
    <div class="d-flex">
      <div class="form-group">
        <div class="title">{{ productLabel }}</div>
        <v-select
          :placeholder="searchProductPlaceholder"
          label="name"
          :options="productList"
          @search="searchProduct"
          v-model="selectedProduct"
        >
          <template slot="no-options">
            {{ typeProductPlaceholder }}
          </template>
        </v-select>
      </div>
      <div class="form-group">
        <div class="title">{{ variantLabel}}</div>
        <select
          v-model="product_variant_id"
          class="form-control"
          :disabled="
            !selectedProduct || selectedProduct.product_variants.length == 0
          "
        >
          <option
            v-for="(variant, index) in selectedProduct.product_variants"
            :key="index"
            :value="variant.id"
            >{{ variant.name }}</option
          >
        </select>
      </div>
      <div class="form-group">
        <div class="title">{{ quantityLabel }}</div>
        <input
          class="form-control"
          :placeholder="quantityLabel"
          type="number"
          step="1"
          v-model="quantity"
        />
      </div>
      <div class="control">
        <button
          type="button"
          class="btn btn-add btn-primary"
          @click="createInventoryTransaction"
        >
          +
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "branchId",
    "token",
    "locale",
    "skuLabel",
    "productLabel",
    "variantLabel",
    "quantityLabel",
    "productError",
    "quantityError",
    "searchProductPlaceholder",
    "typeProductPlaceholder",
    "createSuccessMessage",
  ],
  data() {
    return {
      productList: [],
      selectedProduct: "",
      selectedVariantSku: "",
      product_id: null,
      product_variant_id: null,
      quantity: 0,
      productVariantListBySku: [],
      productListBySku: []
    };
  },
  watch: {
    selectedProduct: function(val) {
      if (val) {
        this.product_id = val.id;
        this.product_variant_id =
          val.product_variants.length > 0 ? val.product_variants[0].id : null;
      } else {
        this.product_id = null;
        this.product_variant_id = null;
      }
    },
    selectedVariantSku: function(val) {
      if (val) {
        this.selectedProduct = this.productList.find(x=>x.id === val.product_id)
        this.product_id = val.product_id;
        this.product_variant_id = val.id;
      } else {
        this.selectedProduct = ""
        this.product_id = null;
        this.product_variant_id = null;
      }
    }
  },
  mounted() {
    this.getProducts();
  },
  methods: {
    getProducts () {
      this.$http
        .get(`/api/v1/products`)
        .then((response) => {
          const data = response.data.data
          const dataToCut = 5
          this.productList = data.length > dataToCut ? data.slice(0,dataToCut) : data;
          this.productListBySku = _.filter(data, (o)=>{
            return o.sku !== ""
          })
          this.productVariantListBySku =_.flattenDeep(data.map(product=>{
            return product.product_variants
          }))
        })
        .catch((error) => {
          console.log(error);
        });
    },
    searchProduct(search, loading) {
      if (search.length) {
        loading(true);
        this.search(loading, search, this);
      }
    },
    search: _.debounce((loading, search, vm) => {
      vm.$http
        .get(`/api/v1/products?name=${search}`)
        .then((response) => {
          vm.productList = response.data.data;
          loading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }, 350),
    createInventoryTransaction() {
      const vm = this;
      if (this.validation()) {
        this.$http
          .post(`/api/v1/branches/${this.branchId}/inventory_transactions`, {
            authenticity_token: this.token,
            inventory_transaction: {
              product_id: this.product_id,
              product_variant_id: this.product_variant_id,
              quantity: this.quantity,
            },
          })
          .then((response) => {
            vm.$toast.open({
              message: vm.createSuccessMessage,
              type: "success",
              position: "top-right",
            });
            setTimeout(function(){ 
              window.location.reload()
            }, 500);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    validation() {
      if (!this.product_id) {
        this.error = this.productError;
      } else if (!this.quantity) {
        this.error = this.quantityError
      } else {
        this.error = "";
      }

      return this.error == "";
    },
  },
};
</script>

<style lang="scss" scoped>
.d-flex {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 2rem;
}
.form-group {
  width: 50%;
  margin-bottom: 0;
  &:not(:last-child){
    margin-right: .5rem;
  }
}
.btn-add {
  height: 45px;
}
select[disabled="disabled"] {
  background: #e5e5e5 !important;
}
@media only screen and (max-width: 450px) {
  .d-flex {
    flex-wrap: wrap;
  }
  .form-group {
    width: 100%;
    margin-bottom: 1rem;
  }
  .control,
  .btn-add {
    display: block;
    width: 100%;
  }
}
</style>
<style>
.vs__dropdown-toggle {
  width: 100% !important;
  padding: 8px !important;
  border: 1px solid #e5e5e5 !important;
  border-radius: 5px !important;
  font-size: 14px !important;
  line-height: 1 !important;
  height: auto !important;
}
.vs__dropdown-menu{
  /* max-height: 120px !important; */
  overflow-y: hidden !important;
  max-height: 134px !important;
}
</style>
