<template>
  <div>
    <div class="row">
      <!-- loop exits -->
      <div v-show="img._destroy != 1" class="col-sm-3 col-6" v-for="(img, index) in documentsData" v-bind:key="'photo'+index">
        <a :href="img.file.url" target="_blank">{{ viewFileLabel }}</a>
        <div>
          <input v-model="img.id" type="hidden" :name="'store['+inputName+']['+index+'][id]'">
          <input v-if="img.destroy" v-model="img.destroy" type="hidden" :name="'store['+inputName+']['+index+'][_destroy]'">
        </div>
        <div v-if="!disabled" @click="deleteFile(index)" class="text-center mb-3 mt-1 text-danger">
          <i class="fas fa-trash control"></i> 
          <small class="control">{{ deleteLabel }}</small>
        </div>
      </div>
      <!-- loop preivew -->
      <div class="col-sm-3 col-6" v-for="(img, index) in documentPreview" v-bind:key="'photoPreview'+index">
        <p>{{img.name}}</p>
        <input type="hidden" :name="'store['+inputName+']['+index+'][photo]'" :value="img">
        <div @click="deletePreview(index)" class="text-center mb-3 mt-1 text-danger">
          <i class="fas fa-trash control"></i> 
          <small class="control">{{ deleteLabel }}</small>
        </div>
      </div>
    </div>
    <input type="file" :id="inputName" @change="previewUpload($event)" accept="application/pdf,image/*" multiple>
    <div v-if="documentPreview.length == 0 && documentsData.length == 0 && !disabled" class="image">
      <label :for="inputName" class="image-placeholder">{{imagePlaceholderLabel}}</label> 
    </div>
    <div v-else-if="!disabled">
      <label :for="inputName" class="btn btn-sm btn-primary">{{addImageLabel}}</label>
    </div>
  </div>
</template>


<script>
export default {
  name: "multiple-document-input",
  props: ['storeDocuments', 'inputName', 'imagePlaceholderLabel', 'addImageLabel', 'deleteLabel', 'viewFileLabel', 'disabled', 'maxFileLength', 'minFileLength'],
  data() {
    return {
      documentPreview: [],
      documentsData: [],
    };
  },
  watch: {
    documentPreview: function(val) {
      if (typeof this.minFileLength != 'undefined' && typeof this.maxFileLength != 'undefined') {
        var documentLength = 0

        if (this.documentsData.length > 0) {
          this.documentsData.forEach(function(doc) {
            if(typeof doc._destroy == 'undefined') {
              documentLength++
            }
          })
        }

        documentLength += val.length
        this.$emit('documentFound', documentLength >= this.minFileLength && documentLength <= this.maxFileLength);
        this.documentUpdated()
      }
      else {
        var documentFound = false
        if (this.documentsData.length > 0) {
          this.documentsData.forEach(function(doc) {
            if(typeof doc._destroy == 'undefined') {
              documentFound = true
            }
          })
        }

        if(val.length > 0) {
          documentFound = true
        }
        this.$emit('documentFound', documentFound);
        this.documentUpdated()
      }
    },
    documentsData: function(val) {
      if (typeof this.minFileLength != 'undefined' && typeof this.maxFileLength != 'undefined') {
        var documentLength = 0

        if (val.length > 0) {
          val.forEach(function(doc) {
            if(typeof doc._destroy == 'undefined') {
              documentLength++
            }
          })
        }

        documentLength += this.documentPreview.length
        this.$emit('documentFound', documentLength >= this.minFileLength && documentLength <= this.maxFileLength);
        this.documentUpdated()
      }
      else {
        var documentFound = false
        if (val.length > 0) {
          val.forEach(function(doc) {
            if(typeof doc._destroy == 'undefined') {
              documentFound = true
            }
          })
        }

        if(this.documentPreview.length > 0) {
          documentFound = true
        }
        this.$emit('documentFound', documentFound);
        this.documentUpdated()
      }
    },
  },
  mounted() {
    this.documentsData = this.storeDocuments ? this.storeDocuments : [];
  },
  methods: {
    async previewUpload(e) {
      const files = e.target.files;
      const vm = this;
      this.documentPreview = [];
      var documentLength = 0
      this.documentsData.forEach(function(doc) {
        if(typeof doc._destroy == 'undefined') {
          documentLength++
        }
      })
      let max = this.maxFileLength - documentLength
      for (let i = 0; i < files.length; i++) {
        if(i < max) {
          vm.documentPreview.push({
            name: files[i].name,
            file: await this.toBase64(files[i])
          })
        }
      }
    },
    deleteFile(index) {
      this.$set(this.documentsData[index], "_destroy", true);
    },
    deletePreview(index) {
      this.documentPreview.splice(index, 1);
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader()
        reader.onload = () => {
          resolve(reader.result)
        }
        reader.onerror = reject
        reader.readAsDataURL(file)
      })
    },
    documentUpdated() {
      var documents = []
      this.documentsData.forEach(function(doc) {
        documents.push({
          id: doc.id,
          _destroy: doc._destroy ? doc._destroy : false
        })
      })
      this.documentPreview.forEach(function(doc) {
        documents.push({
          file: doc.file,
        })
      })
      this.$emit('documentUpdate', documents);
    }
  }
};
</script>

<style scoped>
.control:hover {
  cursor: pointer;
}
.row {
  display: flex;
  flex-wrap: wrap;
}
.col-sm-3.col-6 {
  width: 20%;
  margin-right: 10px;
  margin-bottom: 10px;
}
</style>
