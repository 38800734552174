<script>
import { Line } from 'vue-chartjs'

export default {
  extends: Line,
  name: 'LineChart',
  props: ['chartData', 'options'],
  chartData: function() {
    return this.chartData;
  },
  mounted () {
    this.renderLineChart();
  },
  watch: {
    chartData: function() {
      this.$data._chart.destroy();
      this.renderLineChart();
    }
  },
  methods: {
    renderLineChart: function() {
      this.renderChart(this.chartData,this.options);      
    }
  },
}
</script>