<template>
  <form @submit.prevent="onSubmit">
    <div class="form-group">
      <div class="title">{{ branchLocationLabel }}</div>
      <select class="form-control" v-model="location_id">
        <option
          v-for="(location, index) in locations"
          :key="`location${index}`"
          :value="location.id"
          >{{ location.location_name }}</option
        >
      </select>
      <div v-if="error != ''" class="mb-2 text-danger">
        {{ error }}
      </div>
    </div>
    <input
      type="submit"
      class="btn btn-large btn-block btn-primary"
      :value="connectLabel"
    />
  </form>
</template>

<script>
export default {
  props: [
    "token",
    "branchId",
    "branchLocationLabel",
    "connectLabel",
    "connectSuccessMessage",
    "connectFailMessage",
    "somethingWrongMessaeg",
    "branchLocationRequired",
    "itemLocationId",
    "locale",
  ],
  data() {
    return {
      location_id: "",
      locations: [],
      error: "",
    };
  },
  mounted() {
    this.getLocations();
    if(typeof this.itemLocationId !== 'undefined') {
      this.location_id = this.itemLocationId
    }
  },
  methods: {
    getLocations() {
      const vm = this;
      this.$http
        .get("/api/v1/stores/item_locations")
        .then((response) => {
          vm.locations = response.data.data;
        })
        .catch((error) => {
          vm.$toast.open({
            message: vm.somethingWrongMessaeg,
            type: "error",
            position: "top-right",
          });
          console.log(error);
        });
    },
    onSubmit() {
      const vm = this;
      if (this.location_id != "") {
        console.log('1')
        this.error = "";
        this.$http
          .put(`/api/v1/branches/${this.branchId}`, {
            authenticity_token: this.token,
            branch: {
              item_location_id: this.location_id,
            }
          })
          .then((response) => {
            vm.$toast.open({
              message: vm.connectSuccessMessage,
              type: "success",
              position: "top-right",
            });
          })
          .catch((error) => {
            vm.$toast.open({
              message: vm.connectFailMessage,
              type: "error",
              position: "top-right",
            });
            console.log(error);
          });
      } else {
        this.error = this.branchLocationRequired;
      }
    },
  },
};
</script>

<style scoped>
.text-danger {
  margin-bottom: 0.5rem;
  color: red;
}
</style>
