<template>
  <div>
    <md-switch v-model="is_checked">{{ is_checked ? labelTrue : labelFalse }}</md-switch>
    <input type="hidden" :name="name" :value="is_checked ? 'true' : 'false'">
  </div>
</template>

<script>
  export default {
    props: ['labelTrue', 'labelFalse', 'value', 'name'],
    data() {
      return {
        is_checked: null,
      }
    },
    computed: {},
    watch: {},
    mounted() {
      if(typeof this.value != 'undefined') {
        this.is_checked = (this.value == 'true' ? true : false);
      }
    },
    methods: {},
  }
</script>