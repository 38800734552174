<template>
    <div>
        <span v-if="businessNature == '47'">{{ locale == "cn" ? "会计" : "Accounting" }}</span>
        <span v-if="businessNature == '94'">{{ locale == "cn" ? "航空公司/航空" : "Airlines/Aviation" }}</span>
        <span v-if="businessNature == '120'">{{ locale == "cn" ? "替代性纠纷解决" : "Alternative Dispute Resolution" }}</span>
        <span v-if="businessNature == '125'">{{ locale == "cn" ? "替代药物" : "Alternative Medicine" }}</span>
        <span v-if="businessNature == '127'">{{ locale == "cn" ? "动画片" : "Animation" }}</span>
        <span v-if="businessNature == '19'">{{ locale == "cn" ? "服装与时尚" : "Apparel & Fashion" }}</span>
        <span v-if="businessNature == '50'">{{ locale == "cn" ? "建筑与规划" : "Architecture & Planning" }}</span>
        <span v-if="businessNature == '111'">{{ locale == "cn" ? "美术和工艺" : "Arts and Crafts" }}</span>
        <span v-if="businessNature == '53'">{{ locale == "cn" ? "汽车" : "Automotive" }}</span>
        <span v-if="businessNature == '52'">{{ locale == "cn" ? "航空航天" : "Aviation & Aerospace" }}</span>
        <span v-if="businessNature == '41'">{{ locale == "cn" ? "银行业" : "Banking" }}</span>
        <span v-if="businessNature == '12'">{{ locale == "cn" ? "生物技术" : "Biotechnology" }}</span>
        <span v-if="businessNature == '36'">{{ locale == "cn" ? "广播媒体" : "Broadcast Media" }}</span>
        <span v-if="businessNature == '49'">{{ locale == "cn" ? "建筑材料" : "Building Materials" }}</span>
        <span v-if="businessNature == '138'">{{ locale == "cn" ? "商业用品和设备" : "Business Supplies and Equipment" }}</span>
        <span v-if="businessNature == '129'">{{ locale == "cn" ? "资本市场" : "Capital Markets" }}</span>
        <span v-if="businessNature == '54'">{{ locale == "cn" ? "化学品" : "Chemicals" }}</span>
        <span v-if="businessNature == '90'">{{ locale == "cn" ? "公民和社会组织" : "Civic & Social Organization" }}</span>
        <span v-if="businessNature == '51'">{{ locale == "cn" ? "土木工程" : "Civil Engineering" }}</span>
        <span v-if="businessNature == '128'">{{ locale == "cn" ? "商业房地产" : "Commercial Real Estate" }}</span>
        <span v-if="businessNature == '118'">{{ locale == "cn" ? "计算机与网络安全" : "Computer & Network Security" }}</span>
        <span v-if="businessNature == '109'">{{ locale == "cn" ? "电脑游戏" : "Computer Games" }}</span>
        <span v-if="businessNature == '3'">{{ locale == "cn" ? "电脑硬件" : "Computer Hardware" }}</span>
        <span v-if="businessNature == '5'">{{ locale == "cn" ? "计算机网络" : "Computer Networking" }}</span>
        <span v-if="businessNature == '4'">{{ locale == "cn" ? "计算机软件" : "Computer Software" }}</span>
        <span v-if="businessNature == '48'">{{ locale == "cn" ? "建造" : "Construction" }}</span>
        <span v-if="businessNature == '24'">{{ locale == "cn" ? "消费类电子产品" : "Consumer Electronics" }}</span>
        <span v-if="businessNature == '25'">{{ locale == "cn" ? "消费品" : "Consumer Goods" }}</span>
        <span v-if="businessNature == '91'">{{ locale == "cn" ? "消费服务" : "Consumer Services" }}</span>
        <span v-if="businessNature == '18'">{{ locale == "cn" ? "化妆品" : "Cosmetics" }}</span>
        <span v-if="businessNature == '65'">{{ locale == "cn" ? "乳制品" : "Dairy" }}</span>
        <span v-if="businessNature == '1'">{{ locale == "cn" ? "国防与太空" : "Defense & Space" }}</span>
        <span v-if="businessNature == '99'">{{ locale == "cn" ? "设计" : "Design" }}</span>
        <span v-if="businessNature == '69'">{{ locale == "cn" ? "教育管理" : "Education Management" }}</span>
        <span v-if="businessNature == '132'">{{ locale == "cn" ? "电子学习" : "E-Learning" }}</span>
        <span v-if="businessNature == '112'">{{ locale == "cn" ? "电气/电子制造" : "Electrical/Electronic Manufacturing" }}</span>
        <span v-if="businessNature == '28'">{{ locale == "cn" ? "娱乐" : "Entertainment" }}</span>
        <span v-if="businessNature == '86'">{{ locale == "cn" ? "环境服务" : "Environmental Services" }}</span>
        <span v-if="businessNature == '110'">{{ locale == "cn" ? "活动服务" : "Events Services" }}</span>
        <span v-if="businessNature == '76'">{{ locale == "cn" ? "行政办公室" : "Executive Office" }}</span>
        <span v-if="businessNature == '122'">{{ locale == "cn" ? "设施服务" : "Facilities Services" }}</span>
        <span v-if="businessNature == '63'">{{ locale == "cn" ? "农业" : "Farming" }}</span>
        <span v-if="businessNature == '43'">{{ locale == "cn" ? "金融服务" : "Financial Services" }}</span>
        <span v-if="businessNature == '38'">{{ locale == "cn" ? "美术" : "Fine Art" }}</span>
        <span v-if="businessNature == '66'">{{ locale == "cn" ? "渔业" : "Fishery" }}</span>
        <span v-if="businessNature == '34'">{{ locale == "cn" ? "食品和饮料" : "Food & Beverages" }}</span>
        <span v-if="businessNature == '23'">{{ locale == "cn" ? "食品生产" : "Food Production" }}</span>
        <span v-if="businessNature == '101'">{{ locale == "cn" ? "筹款" : "Fund-Raising" }}</span>
        <span v-if="businessNature == '26'">{{ locale == "cn" ? "家具" : "Furniture" }}</span>
        <span v-if="businessNature == '29'">{{ locale == "cn" ? "赌博和赌场" : "Gambling & Casinos" }}</span>
        <span v-if="businessNature == '145'">{{ locale == "cn" ? "玻璃、陶瓷和混凝土" : "Glass, Ceramics & Concrete" }}</span>
        <span v-if="businessNature == '75'">{{ locale == "cn" ? "政府管理" : "Government Administration" }}</span>
        <span v-if="businessNature == '148'">{{ locale == "cn" ? "政府关系" : "Government Relations" }}</span>
        <span v-if="businessNature == '140'">{{ locale == "cn" ? "平面设计" : "Graphic Design" }}</span>
        <span v-if="businessNature == '124'">{{ locale == "cn" ? "健康、保健和健身" : "Health, Wellness and Fitness" }}</span>
        <span v-if="businessNature == '68'">{{ locale == "cn" ? "高等教育" : "Higher Education" }}</span>
        <span v-if="businessNature == '14'">{{ locale == "cn" ? "医院和医疗保健" : "Hospital & Health Care" }}</span>
        <span v-if="businessNature == '31'">{{ locale == "cn" ? "热情好客" : "Hospitality" }}</span>
        <span v-if="businessNature == '137'">{{ locale == "cn" ? "人力资源" : "Human Resources" }}</span>
        <span v-if="businessNature == '134'">{{ locale == "cn" ? "进出口" : "Import and Export" }}</span>
        <span v-if="businessNature == '88'">{{ locale == "cn" ? "个人及家庭服务" : "Individual & Family Services" }}</span>
        <span v-if="businessNature == '147'">{{ locale == "cn" ? "工业自动化" : "Industrial Automation" }}</span>
        <span v-if="businessNature == '84'">{{ locale == "cn" ? "信息服务" : "Information Services" }}</span>
        <span v-if="businessNature == '96'">{{ locale == "cn" ? "信息技术和服务" : "Information Technology and Services" }}</span>
        <span v-if="businessNature == '42'">{{ locale == "cn" ? "保险" : "Insurance" }}</span>
        <span v-if="businessNature == '74'">{{ locale == "cn" ? "国际事务" : "International Affairs" }}</span>
        <span v-if="businessNature == '141'">{{ locale == "cn" ? "国际贸易与发展" : "International Trade and Development" }}</span>
        <span v-if="businessNature == '6'">{{ locale == "cn" ? "互联网" : "Internet" }}</span>
        <span v-if="businessNature == '45'">{{ locale == "cn" ? "投资银行" : "Investment Banking" }}</span>
        <span v-if="businessNature == '46'">{{ locale == "cn" ? "投资管理" : "Investment Management" }}</span>
        <span v-if="businessNature == '73'">{{ locale == "cn" ? "司法" : "Judiciary" }}</span>
        <span v-if="businessNature == '77'">{{ locale == "cn" ? "执法" : "Law Enforcement" }}</span>
        <span v-if="businessNature == '9'">{{ locale == "cn" ? "法律实践" : "Law Practice" }}</span>
        <span v-if="businessNature == '10'">{{ locale == "cn" ? "法律服务" : "Legal Services" }}</span>
        <span v-if="businessNature == '72'">{{ locale == "cn" ? "立法局" : "Legislative Office" }}</span>
        <span v-if="businessNature == '30'">{{ locale == "cn" ? "休闲、旅行和旅游" : "Leisure, Travel & Tourism" }}</span>
        <span v-if="businessNature == '85'">{{ locale == "cn" ? "图书馆" : "Libraries" }}</span>
        <span v-if="businessNature == '116'">{{ locale == "cn" ? "物流与供应链" : "Logistics and Supply Chain" }}</span>
        <span v-if="businessNature == '143'">{{ locale == "cn" ? "奢侈品和珠宝" : "Luxury Goods & Jewelry" }}</span>
        <span v-if="businessNature == '55'">{{ locale == "cn" ? "机械" : "Machinery" }}</span>
        <span v-if="businessNature == '11'">{{ locale == "cn" ? "管理咨询" : "Management Consulting" }}</span>
        <span v-if="businessNature == '95'">{{ locale == "cn" ? "海上" : "Maritime" }}</span>
        <span v-if="businessNature == '80'">{{ locale == "cn" ? "市场营销和广告" : "Marketing and Advertising" }}</span>
        <span v-if="businessNature == '97'">{{ locale == "cn" ? "市场调查" : "Market Research" }}</span>
        <span v-if="businessNature == '135'">{{ locale == "cn" ? "机械或工业工程" : "Mechanical or Industrial Engineering" }}</span>
        <span v-if="businessNature == '126'">{{ locale == "cn" ? "媒体制作" : "Media Production" }}</span>
        <span v-if="businessNature == '17'">{{ locale == "cn" ? "医疗设备" : "Medical Devices" }}</span>
        <span v-if="businessNature == '13'">{{ locale == "cn" ? "医疗实践" : "Medical Practice" }}</span>
        <span v-if="businessNature == '139'">{{ locale == "cn" ? "心理保健" : "Mental Health Care" }}</span>
        <span v-if="businessNature == '71'">{{ locale == "cn" ? "军队" : "Military" }}</span>
        <span v-if="businessNature == '56'">{{ locale == "cn" ? "采矿和金属" : "Mining & Metals" }}</span>
        <span v-if="businessNature == '35'">{{ locale == "cn" ? "电影和电影" : "Motion Pictures and Film" }}</span>
        <span v-if="businessNature == '37'">{{ locale == "cn" ? "博物馆和机构" : "Museums and Institutions" }}</span>
        <span v-if="businessNature == '115'">{{ locale == "cn" ? "音乐" : "Music" }}</span>
        <span v-if="businessNature == '114'">{{ locale == "cn" ? "纳米技术" : "Nanotechnology" }}</span>
        <span v-if="businessNature == '81'">{{ locale == "cn" ? "报纸" : "Newspapers" }}</span>
        <span v-if="businessNature == '100'">{{ locale == "cn" ? "非营利组织管理" : "Nonprofit Organization Management" }}</span>
        <span v-if="businessNature == '57'">{{ locale == "cn" ? "石油与能源" : "Oil & Energy" }}</span>
        <span v-if="businessNature == '113'">{{ locale == "cn" ? "网络媒体" : "Online Media" }}</span>
        <span v-if="businessNature == '123'">{{ locale == "cn" ? "外包/离岸" : "Outsourcing/Offshoring" }}</span>
        <span v-if="businessNature == '87'">{{ locale == "cn" ? "包裹/货运" : "Package/Freight Delivery" }}</span>
        <span v-if="businessNature == '146'">{{ locale == "cn" ? "包装和容器" : "Packaging and Containers" }}</span>
        <span v-if="businessNature == '61'">{{ locale == "cn" ? "纸和林产品" : "Paper & Forest Products" }}</span>
        <span v-if="businessNature == '39'">{{ locale == "cn" ? "表演艺术" : "Performing Arts" }}</span>
        <span v-if="businessNature == '15'">{{ locale == "cn" ? "制药" : "Pharmaceuticals" }}</span>
        <span v-if="businessNature == '131'">{{ locale == "cn" ? "慈善事业" : "Philanthropy" }}</span>
        <span v-if="businessNature == '136'">{{ locale == "cn" ? "摄影" : "Photography" }}</span>
        <span v-if="businessNature == '117'">{{ locale == "cn" ? "塑料" : "Plastics" }}</span>
        <span v-if="businessNature == '107'">{{ locale == "cn" ? "政治组织" : "Political Organization" }}</span>
        <span v-if="businessNature == '67'">{{ locale == "cn" ? "小学/中学教育" : "Primary/Secondary Education" }}</span>
        <span v-if="businessNature == '83'">{{ locale == "cn" ? "印刷" : "Printing" }}</span>
        <span v-if="businessNature == '105'">{{ locale == "cn" ? "专业培训与辅导" : "Professional Training & Coaching" }}</span>
        <span v-if="businessNature == '102'">{{ locale == "cn" ? "程序开发" : "Program Development" }}</span>
        <span v-if="businessNature == '79'">{{ locale == "cn" ? "公共政策" : "Public Policy" }}</span>
        <span v-if="businessNature == '98'">{{ locale == "cn" ? "公共关系和传播" : "Public Relations and Communications" }}</span>
        <span v-if="businessNature == '78'">{{ locale == "cn" ? "公共安全" : "Public Safety" }}</span>
        <span v-if="businessNature == '82'">{{ locale == "cn" ? "出版" : "Publishing" }}</span>
        <span v-if="businessNature == '62'">{{ locale == "cn" ? "铁路制造" : "Railroad Manufacture" }}</span>
        <span v-if="businessNature == '64'">{{ locale == "cn" ? "牧场" : "Ranching" }}</span>
        <span v-if="businessNature == '44'">{{ locale == "cn" ? "房地产" : "Real Estate" }}</span>
        <span v-if="businessNature == '40'">{{ locale == "cn" ? "康乐设施和服务" : "Recreational Facilities and Services" }}</span>
        <span v-if="businessNature == '89'">{{ locale == "cn" ? "宗教机构" : "Religious Institutions" }}</span>
        <span v-if="businessNature == '144'">{{ locale == "cn" ? "可再生能源与环境" : "Renewables & Environment" }}</span>
        <span v-if="businessNature == '70'">{{ locale == "cn" ? "研究" : "Research" }}</span>
        <span v-if="businessNature == '32'">{{ locale == "cn" ? "餐厅" : "Restaurants" }}</span>
        <span v-if="businessNature == '27'">{{ locale == "cn" ? "零售" : "Retail" }}</span>
        <span v-if="businessNature == '121'">{{ locale == "cn" ? "安全和调查" : "Security and Investigations" }}</span>
        <span v-if="businessNature == '7'">{{ locale == "cn" ? "半导体" : "Semiconductors" }}</span>
        <span v-if="businessNature == '58'">{{ locale == "cn" ? "造船" : "Shipbuilding" }}</span>
        <span v-if="businessNature == '20'">{{ locale == "cn" ? "体育用品" : "Sporting Goods" }}</span>
        <span v-if="businessNature == '33'">{{ locale == "cn" ? "运动" : "Sports" }}</span>
        <span v-if="businessNature == '104'">{{ locale == "cn" ? "人员配备和招聘" : "Staffing and Recruiting" }}</span>
        <span v-if="businessNature == '22'">{{ locale == "cn" ? "超级市场" : "Supermarkets" }}</span>
        <span v-if="businessNature == '8'">{{ locale == "cn" ? "电信" : "Telecommunications" }}</span>
        <span v-if="businessNature == '60'">{{ locale == "cn" ? "纺织品" : "Textiles" }}</span>
        <span v-if="businessNature == '130'">{{ locale == "cn" ? "智库" : "Think Tanks" }}</span>
        <span v-if="businessNature == '21'">{{ locale == "cn" ? "烟草" : "Tobacco" }}</span>
        <span v-if="businessNature == '108'">{{ locale == "cn" ? "翻译和本地化" : "Translation and Localization" }}</span>
        <span v-if="businessNature == '92'">{{ locale == "cn" ? "运输/货运/铁路" : "Transportation/Trucking/Railroad" }}</span>
        <span v-if="businessNature == '59'">{{ locale == "cn" ? "公用事业" : "Utilities" }}</span>
        <span v-if="businessNature == '106'">{{ locale == "cn" ? "风险投资和私募股权" : "Venture Capital & Private Equity" }}</span>
        <span v-if="businessNature == '16'">{{ locale == "cn" ? "兽医" : "Veterinary" }}</span>
        <span v-if="businessNature == '93'">{{ locale == "cn" ? "仓储" : "Warehousing" }}</span>
        <span v-if="businessNature == '133'">{{ locale == "cn" ? "批发" : "Wholesale" }}</span>
        <span v-if="businessNature == '142'">{{ locale == "cn" ? "葡萄酒和烈酒" : "Wine and Spirits" }}</span>
        <span v-if="businessNature == '119'">{{ locale == "cn" ? "无线的" : "Wireless" }}</span>
        <span v-if="businessNature == '103'">{{ locale == "cn" ? "写作和编辑" : "Writing and Editing" }}</span>
    </div>
</template>

<script>
export default {
  props: [
    "businessNature",
    "locale",
  ],
};
</script>
