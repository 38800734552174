<template>
  <form class="integration-form" @submit.prevent="onSubmit">
    <div class="form-group">
      <div class="title">{{ usernameLabel }}</div>
      <input
        type="text"
        class="form-control"
        :placeholder="usernameLabel"
        v-model="username"
      />
    </div>
    <div class="form-group">
      <div class="title">{{ passwordLabel }}</div>
      <input
        type="password"
        class="form-control"
        :placeholder="passwordLabel"
        v-model="password"
      />
    </div>
    <input
      type="submit"
      class="btn btn-large btn-block btn-primary"
      :value="connectLabel"
    />
  </form>
</template>

<script>
export default {
  props: [
    "token",
    "usernameLabel",
    "passwordLabel",
    "connectLabel",
    "connectSuccessMessage",
    "connectFailMessage",
    "locale",
  ],
  data() {
    return {
      username: "",
      password: "",
    };
  },
  methods: {
    onSubmit() {
      const vm = this
      this.$http
        .post("/api/v1/stores/connect_loyalty", {
          authenticity_token: this.token,
          username: this.username,
          password: this.password,
        })
        .then((response) => {
          vm.$toast.open({
            message: vm.connectSuccessMessage,
            type: "success",
            position: "top-right",
          });
          window.location.replace(`/${vm.locale}/store`);
        })
        .catch((error) => {
          vm.$toast.open({
            message: vm.connectFailMessage,
            type: "error",
            position: "top-right",
          });
          console.log(error);
        });
    },
  },
};
</script>
