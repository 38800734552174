<template>
  <div :class="layout" id="locale-picker">
    <span :class="(lang == 'en' ? 'selected' : '')" @click="change('en')">EN</span>
    <span :class="(lang == 'cn' ? 'selected' : '')" @click="change('cn')">中</span>
  </div>
</template>


<script>
  export default {
    props: ['layout', 'input', 'url'],
    data(){
      return {
        lang: null,
      }
    },
    watch: {},
    mounted() {
      this.lang = this.input;
    },
    methods: {
      change(lang) {
        if(lang != this.lang) {
          this.lang = lang;
          var url = this.url;
          if(this.lang == 'cn') {
            url = this.url.replace('en', 'cn');
          }
          else {
            if(this.url.includes('cn'))
              url = this.url.replace('cn', 'en')
            else 
              url = '/en'+this.url;
          }

          window.location.href = url;
        }
      }
    },
  }
</script>

<style scoped>
#locale-picker {
  border-radius: 5px;
  display: flex;
  width: 80px;
  overflow: hidden;
  margin: auto;
  box-shadow: 0px 2px 10px rgba(95, 95, 95, 0.25);
}
#locale-picker span {
  padding: .75rem;
  color: #000;
  background: #fff;
  font-weight: bold;
  width: 40px;
  cursor: pointer;
}
#locale-picker .selected {
  background: rgb(249,183,62);
  color: #fff;
}
#locale-picker.header {
  transform: translateY(-10px);
  width: 70px;
}
#locale-picker.header span{
  width: 35px;
  padding: .65rem;
}
#locale-picker.no-margin {
  margin: unset;
}
</style>