<template>
  <div :class="bannerClass">
    <carousel :loop="true" :perPageCustom="[ [0, 1], [440, 2], [650, 3], [766, 2], [933, 3]]" :scroll-per-page="true" :autoplay="true" :pagination-enabled="false">
      <slide v-for="(slide, index) in events" :key="index" >
        <div class="overview" @click="openPopUp(index)">
          <!-- <img :src="imageUrl" alt=""> -->
          <md-card>
            <h2>{{ slide[`name_${locale}`] }}</h2>
            <h3>{{ slide[`event_type_${locale}`] }}</h3>
            <h4>
              {{ getDate(slide.start_date) }}
              {{ toLabel }}
              {{ getDate(slide.end_date) }}
            </h4>
          </md-card>
        </div>
      </slide>
    </carousel>
    <md-dialog :md-active.sync="showDialog" class="bg-white">
      <div class="close" @click="showDialog = false">X</div>
      <md-content class="contents">
        <!-- <img :src="imageUrl" alt=""> -->
        <!-- <div class="card-section">
          <md-card>

            <h2>Back To School</h2>
            <h3>50% Discount</h3>
            <h4>2021-03-26 - 2021-04-10</h4>
          </md-card>
        </div> -->
        <div class="criterias">
          <div class="criteria-content">
            <h1>{{ event[`name_${locale}`] }}</h1>
            <h3>{{ event[`event_type_${locale}`] }}</h3>
            <h4>
              {{ getDate(event.start_date) }}
              {{ toLabel }}
              {{ getDate(event.end_date) }}
            </h4>
            <div>
              <div v-for="amount in event.criteria_set_amount" :key="amount" style="border-top:1px solid;" >
                <h2>{{criteriaLabel}}{{amount}} :</h2>
                <template v-for="criteria in event.criterias" >
                  <div v-if="criteria.calculation_type !== 'not_available' && criteria.criteria_set === amount" :key="criteria.id"  class="criteria">
                    <span><h3>{{criteria[`criteria_description_${locale}`]}}</h3></span>
                    <p>
                      {{ criteria.coin_symbol != null ? criteria.coin_symbol : criterias[criteria.criteria_set] }} : {{ (criteria.code ===  "REW_COIN_AMT" && criteria.calculation_type !== "fixed") ? `${_.round(criteria.figure,2)}%` : criteria.figure }}  <br>
                    </p>
                  </div>
                </template>
              </div>
            </div>
            <!-- <div>
              <h2>Wallets Available :</h2>
              <div>
                VVVV : 1.00 <br>
                UBG : 1.00
              </div>
            </div> -->
          </div>
        </div>
      </md-content>
      <div class="navigation">
        <button class="next-prev" @click="goToPrevSlide()"> &lt; </button>
        <button class="next-prev" @click="goToNextSlide()"> &gt; </button>
      </div>
    </md-dialog>
  </div>
</template>

<script>
  import { Carousel, Slide } from 'vue-carousel';

  export default {
    props: [ "token", "locale", "image-url", "criteriaLabel", "toLabel", "bannerClass"],
    components: {
      Carousel,
      Slide,
    },
    data() {
      return {
        showDialog: false,
        event: {},
        events: [],
        criterias: {},
        eventsIndex: 0,
      }
    },
    watch: {
      eventsIndex: function (val){
        this.event = this.events[val];
      }
    },
    mounted() {
      this.getEvents()
    },
    methods: {
      getEvents (){
        this.$http.get('/api/v1/loyalties/events')
          .then((res)=>{
            this.events = res.data.data
          }).catch((err)=>{
            // console.log(err)
          })
      },
      getDate (date) {
        const new_date = new Date(date)

        return `${new_date.getDate()}-${new_date.getMonth()}-${new_date.getFullYear()}`
      },
      goToPrevSlide (){
        if (this.eventsIndex > 0){
          this.eventsIndex -= 1;
        }
      },
      goToNextSlide (){
        if (this.eventsIndex + 1 < this.events.length){
          this.eventsIndex += 1;
        }
      },
      openPopUp(index){
        if (!this.showDialog){
          this.showDialog = true;
        }
        this.eventsIndex = index;
        this.event = this.events[index];
      }
    },
  }
</script>

<style lang="scss">
  @media screen and (min-width: 600px) {
    .bg-white{
      .md-dialog-container{
        min-width: 420px;
      }
    }
  }
  .bg-white{
    .navigation{
      right: 10px;
      bottom: 10px;
      position: absolute;
      display: flex;
      justify-content: space-between;
      width: 80px !important;
      .next-prev{
        // top: unset;
        // left: unset;
        // right: 10px;
        // bottom: 10px;
        font-size: 25px;
        border: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35px;
        height: 35px;
        color: #fff;
        line-height: 26px;    
        background: rgba(0, 0, 0, 0.5);    
        border-radius: 50%;
        cursor: pointer;
      }
    }
    .close{
      border-radius: 50%;
      background: rgba(0, 0, 0, .5);
      cursor: pointer;
      &:hover{
        background: red;
        border-color: red;
      }
    }
    .md-dialog-container{
      background: white;
      border-radius: 1rem;
      max-width: 520px;
      padding-bottom: 3rem;
      padding-top: 3rem;
      .contents{
        overflow: auto;
        &::-webkit-scrollbar {
          display: none;
        }
        img{
          width: 100%;
          height: 235px;
        }
        .card-section{
          display: flex;
          justify-content: center;
          align-items: flex-start;
          height: 80px;
          .md-card{
            h1{
              margin-top: .4em;
            }
            h1,h3{
              margin-bottom: 0.6em;
              line-height: 1;
            }
            h4{
              margin: 0;
            }
            margin-top: -5rem ;
            width: 80%;
            background: white;
            height: 140px;
            border-radius: 1rem;
            padding: 1rem 2.5rem;
          }
        }
        .criterias{
          // padding-top: 2rem;
          // padding-top: 3rem;
          .criteria-content{
            padding: 0 1.5rem;
            width: 80%;
            margin: 0 auto;
            .criteria{
              p{
                margin-top: 0.5rem;
              }
              span{
                h3{
                  margin-bottom: 0.5rem;
                }
              }
            }
          }
        }
      }
    }
  }
  .VueCarousel {
    margin-bottom: 1rem;
    &-slide{
      padding-right: .4rem;
      // height: 40%;
      img{
      // height: 97px;
        width: 100%;
        border-radius: .4rem;
      }
      .overview{
        margin: .5rem 0;
        cursor: pointer;
        .md-card{
          h2,h1{
            margin-top: .4em;
            margin-bottom: 0.3em;
            line-height: 1;
          }
          h3{
            margin-top: 0;
            margin-bottom: 0.6em;
          }
          h4{
            margin: 0;
          }
          // width: 80%;
          background: white;
          // height: 140px;
          border-radius: 1rem;
          padding: 1rem 2.5rem;
        }
      }
    }
  }
  .banner{
    width: 80%;
    margin: auto;
  }
  @media screen and (max-width: 991px){
    .banner{
      width: calc(100% - 2rem);
    }
  }
  @media screen and (max-width: 768px){
    .banner{
      width: 100%;
    }
  }
</style>