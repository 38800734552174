<template>
  <div>
    <carousel :loop="true" :autoplay="true" :pagination-enabled="false" :per-page="4">
      <slide v-for="(image, index) in galleryArray" :key="index" >
        <img :src="image.photo.url" alt="">
      </slide>
    </carousel>
  </div>
</template>

<script>
  import { Carousel, Slide } from 'vue-carousel';

  export default {
    props: [ "featuredPhoto", "gallery"],
    components: {
      Carousel,
      Slide,
    },
    data() {
      return {
        galleryArray: [],
      }
    },
    mounted() {
      this.galleryArray = this.gallery ? this.gallery : []
      console.log(this.gallery)
      console.log(this.galleryArray)
    },
  }
</script>