<template>
  <div>
    <div class="row">
      <!-- loop exits -->
      <div v-show="img.destroy != 1" class="col-sm-3 col-6" v-for="(img, index) in photosData" v-bind:key="'photo'+index">
        <img :src="img.photo.url">
        <div>
          <input v-model="img.id" type="hidden" :name="'product['+inputName+']['+index+'][id]'">
          <input v-if="img.destroy" v-model="img.destroy" type="hidden" :name="'product['+inputName+']['+index+'][_destroy]'">
        </div>
        <div @click="deleteFile(index)" class="text-center mb-3 mt-1 text-danger">
          <i class="fas fa-trash control"></i> 
          <small class="control">Delete</small>
        </div>
      </div>
      <!-- loop preivew -->
      <div class="col-sm-3 col-6" v-for="(img, index) in photosPreview" v-bind:key="'photoPreview'+index">
        <img :src="img">
        <input type="hidden" :name="'product['+inputName+']['+index+'][photo]'" :value="img">
      </div>
    </div>
    <input type="file" :id="inputName" @change="previewUpload($event)" accept="image/*" multiple>
    <div v-if="photosPreview.length == 0 && photosData.length == 0" class="image">
      <label :for="inputName" class="image-placeholder">{{imagePlaceholderLabel}}</label> 
    </div>
    <div v-else>
      <label :for="inputName" class="btn btn-sm btn-primary">{{addImageLabel}}</label>
    </div>
  </div>
</template>


<script>
export default {
  name: "multiple-image-input",
  props: ['productPhotos', 'inputName', 'imagePlaceholderLabel', 'addImageLabel'],
  data() {
    return {
      photosPreview: [],
      photosData: [],
    };
  },
  mounted() {
    this.photosData = this.productPhotos ? this.productPhotos : [];
    console.log(this.productPhotos)
  },
  methods: {
    async previewUpload(e) {
      const files = e.target.files;
      const vm = this;
      this.photosPreview = [];
      for (let i = 0; i < files.length; i++) {
        vm.photosPreview.push(await this.toBase64(files[i]));
      }
    },
    deleteFile(index) {
      this.$set(this.photosData[index], "destroy", true);
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader()
        reader.onload = () => {
          resolve(reader.result)
        }
        reader.onerror = reject
        reader.readAsDataURL(file)
      })
    },
  }
};
</script>

<style scoped>
.control:hover {
  cursor: pointer;
}
.row {
  display: flex;
  flex-wrap: wrap;
}
.col-sm-3.col-6 {
  width: 20%;
  margin-right: 10px;
  margin-bottom: 10px;
}
</style>
