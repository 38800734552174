<template>
<div class="form-group">
  <div class="title">{{passwordLabel}}</div>
  <input class="form-control" type="password" :placeholder="passwordLabel" :name="(password != '' ? passwordName : '')" v-model="password">
</div>
</template>

<script>
  export default {
    props: ['passwordLabel', 'passwordName'],
    data() {
      return {
        password: "",
      }
    },
  }
</script>
