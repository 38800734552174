<template>
  <section>
    <div class="innerSetup">
      <h3 class="fixedFont">{{ productVariationLabel }}</h3>
      <label class="check">
        <input type="checkbox" v-model="has_variants" />
        <span class="check-words">
          {{ productVariationCaptionLabel }}
        </span>
        <span class="checkmark"></span>
      </label>
    </div>
    <div v-if="has_variants">
      <div class="innerSetup">
        <div
          v-for="(variant, index) in product_variants"
          :key="index"
          class="variation_inputy"
          v-show="variant.delete != 'true'"
        >
          <div class="wording">
            <p @click="deleteVariant(index)" v-if="product_variants.length > 1">
              {{ removeLabel }}
            </p>
          </div>
          <div class="Variations fixedFont">
            <div class="v-row">
              <h5 class="fixedFont">
                {{ variationLabel }} {{ index + 1 }}
                <span class="xpay-tooltip">
                  <span class="icon">?</span> 
                  <span class="tooltiptext-right">{{variationGuide}}</span>
                </span>
              </h5>
              <input
                type="text"
                class="form-control"
                :name="`product[product_variations_attributes[${index}][name]]`"
                v-model="variant.name"
                @change="checkVariantName(index)"
              />
              <div v-if="variant.name_error != ''" class="error">
                {{ variant.name_error }}
              </div>
            </div>
            <div class="v-row">
              <h5 class="fixedFont">
                {{ variantLabel }}
                <span class="xpay-tooltip">
                  <span class="icon">?</span> 
                  <span class="tooltiptext-right">{{variantGuide}}</span>
                </span>
              </h5>
              <input
                v-if="variant.id != ''"
                type="hidden"
                class="form-control"
                :name="`product[product_variations_attributes[${index}][id]]`"
                v-model="variant.id"
              />
              <input
                v-if="variant.delete == 'true'"
                type="hidden"
                class="form-control"
                :name="`product[product_variations_attributes[${index}][_destroy]]`"
                value="true"
              />
              <div class="option-wrapper">
                <div class="inline-input">
                  <input
                    type="text"
                    class="form-control"
                    v-model="variant.option_input"
                    :placeholder="variantPlaceholder"
                    @keydown.enter.prevent="addOption(index)"
                  />
                  <span class="plus" @click="addOption(index)">+</span>
                </div>
                <div class="option-inner">
                  <span
                    v-for="(option, optionIndex) in variant.options"
                    :key="option.name"
                  >
                    {{ option }}
                    <span @click="deleteOption(index, optionIndex)" class="delete"
                      >x</span
                    >
                    <input
                      type="hidden"
                      :name="
                        `product[product_variations_attributes[${index}][variation_attributes]][]`
                      "
                      :value="option"
                    />
                  </span>
                </div>
                <div v-if="variant.error != ''" class="error">
                  {{ variant.error }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <p @click="addVariant">{{ addOptionLabel }}</p>
      </div>
      <div
        class="innerSetup"
        v-if="products_variantios.some((x) => x.type !== [])"
      >
        <md-table>
          <md-table-row>
            <md-table-head>{{ imageLabel }}</md-table-head>
            <md-table-head>{{ variantLabel }}</md-table-head>
            <md-table-head>{{ currencyLabel }}</md-table-head>
            <md-table-head>{{ priceLabel }}</md-table-head>
            <md-table-head>{{ discountedPriceLabel }}</md-table-head>
            <md-table-head>{{ skuLabel }}</md-table-head>
          </md-table-row>
          <md-table-row
            v-for="(variant, vIndex) in products_variantios"
            :key="vIndex"
          >
            <md-table-cell class="rows">
              <image-input 
              name="product[product_variants_attributes[][photo]]" 
              :id-key="`product_variants_attributes${vIndex}`"
              :value="variant.photo"
              :change-label="imageChangeLabel"
              :image-placeholder="imagePlaceholderLabel"
              style-class="small"
              ></image-input>
            </md-table-cell>
            <md-table-cell class="rows">
              {{ variant.name }}
              <input
                type="hidden"
                name="product[product_variants_attributes[][name]]"
                v-model="variant.name"
              />
              <input
                v-if="variant.id != ''"
                type="hidden"
                name="product[product_variants_attributes[][id]]"
                v-model="variant.id"
              />
            </md-table-cell>
            <md-table-cell class="rows">
              <currency-picker
                input-name="product[product_variants_attributes[][price_currency]]"
                :input-value="variant.price_currency"
              ></currency-picker>
            </md-table-cell>
            <md-table-cell class="rows">
              <input
                type="number"
                name="product[product_variants_attributes[][price]]"
                v-model="variant.price"
                step="0.00000001"
                min="0"
                class="form-control"
                required
              />
            </md-table-cell>
            <md-table-cell class="rows">
              <input
                type="number"
                name="product[product_variants_attributes[][discounted_price]]"
                v-model="variant.discounted_price"
                step="0.00000001"
                min="0"
                class="form-control"
              />
            </md-table-cell>
            <!-- <md-table-cell class="rows">
                <input type="number" name="product[product_variation_attributes[][quantity]]" v-model="variant.quantity" class="form-control" placeholder="0" required/>
            </md-table-cell> -->
            <md-table-cell class="rows">
              <input
                type="text"
                name="product[product_variants_attributes[][sku]]"
                v-model="variant.sku"
                class="form-control"
                required
              />
            </md-table-cell>
          </md-table-row>
        </md-table>
      </div>
    </div>
    <div
      v-for="(variant, vIndex) in productVariantInput"
      :key="vIndex"
      class="delete_variant_row"
    >
      <div v-if="!remain_variants.includes(variant.id)">
        <input
          type="hidden"
          name="product[product_variants_attributes[][id]]"
          :value="variant.id"
        />
        <input
          type="hidden"
          name="product[product_variants_attributes[][_destroy]]"
          value="true"
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: [
    "productVariationInput",
    "productVariantInput",
    "productVariationLabel",
    "productVariationCaptionLabel",
    "variationGuide",
    "variantGuide",
    "variantPlaceholder",
    "removeLabel",
    "addOptionLabel",
    "currencyLabel",
    "variationLabel",
    "imageLabel",
    "variantLabel",
    "quantityLabel",
    "priceLabel",
    "discountedPriceLabel",
    "skuLabel",
    "variantLabel",
    "locale",
    "productVariants",
    "imageChangeLabel",
    "imagePlaceholderLabel"
  ],
  data() {
    return {
      has_variants: false,
      variationCount: 1,
      variationChildCount: 0,
      product_variants: [
        {
          name: "",
          option_input: "",
          options: [],
          name_error: "",
          error: "",
        },
      ],
      products_variantios: [],
      remain_variants: [],
    };
  },
  mounted() {
    let vm = this;
    if (typeof this.productVariationInput !== "undefined" && this.productVariationInput.length > 0) {
      vm.variationCount = 0;
      vm.has_variants = true;
      vm.product_variants = [];
      this.productVariationInput.forEach(function(item) {
        vm.variationCount++;
        vm.product_variants.push({
          id: item.id,
          name: item.name,
          option_input: "",
          options: item.variation_attributes.length > 0 ? item.variation_attributes : [],
          delete: "false",
        });
      });
    }

    this.performGenerate();
  },
  methods: {
    deleteVariant(index) {
      this.variationCount--;
      let product_variants = this.product_variants[index];
      if (product_variants.id == "") {
        this.product_variants.splice(index, 1);
      } else {
        product_variants.delete = "true";
      }

      this.performGenerate();
    },
    addVariant() {
      this.variationCount++;
      this.product_variants.push({
        id: "",
        name: "",
        photo: "",
        name_error: "",
        option_input: "",
        options: [],
      });
      this.performGenerate();
    },
    addOption(index) {
      let product_variants = this.product_variants[index];
      if (product_variants.option_input.trim() != "") {
        if (!product_variants.options.includes(product_variants.option_input)) {
          product_variants.options.push(product_variants.option_input);
          product_variants.option_input = "";
          product_variants.error = "";
          this.variationChildCount++;
        } else {
          product_variants.error =
            product_variants.option_input + this.locale == 'en' ? " is repeated" : " 重复";
        }
      }
      this.performGenerate();
    },
    deleteOption(index, optionIndex) {
      let product_variants = this.product_variants[index];
      product_variants.options.splice(optionIndex, 1);
      this.performGenerate();
    },
    performGenerate() {
      let options = [];
      this.product_variants.forEach(function(item) {
        if (item.delete != "true" && item.options.length > 0) {
          options.push(item.options);
        }
      });
      this.products_variantios = this.generateVariations(options);
    },
    generateVariations(options) {
      let vm = this;
      this.remain_variants = [];
      let newVariant = [];
      let lastElem = options.length;
      if (options.length == 1) {
        options[0].map((obj) => {
          let matchItem = null;
          if (
            typeof this.productVariantInput != "undefined" &&
            this.productVariantInput.length > 0
          ) {
            this.productVariantInput.forEach(function(item) {
              if (item.name == obj) {
                matchItem = item;
              }
            });
          }

          if (matchItem) {
            vm.remain_variants.push(matchItem.id);
            newVariant.push({
              id: matchItem.id,
              name: obj,
              photo: matchItem.photo.url,
              price_currency: matchItem.price_currency,
              price: matchItem.price_currency == 'RMB' || matchItem.price_currency == 'USD' ? Number(matchItem.price_satoshi / 100).toFixed(2) : Number(matchItem.price_satoshi / 100000000).toFixed(8),
              discounted_price: matchItem.price_currency == 'RMB' || matchItem.price_currency == 'USD' ? Number(matchItem.discounted_price_satoshi / 100).toFixed(2) : Number(matchItem.discounted_price_satoshi / 100000000).toFixed(8),
              sku: matchItem.sku,
            });
          } else {
            newVariant.push({
              id: "",
              name: obj,
              photo: '',
              price_currency: $('select[name="product[price_currency]"]').val(),
              price: $('input[name="product[price]"]').val(),
              discounted_price: $('input[name="product[discounted_price]"]').val(),
              sku: $('input[name="product[sku]"]').val() + obj,
            });
          }
        });
      } else {
        let vm = this;
        let id = "";
        this.generateVariations(options.slice(0, lastElem - 1), options).map(
          (first) => {
            options[lastElem - 1].map((sec) => {
              let name = first.name + " / " + sec;
              let matchItem = null;

              if (
                typeof vm.productVariantInput != "undefined" &&
                vm.productVariantInput.length > 0
              ) {
                vm.productVariantInput.forEach(function(item) {
                  if (item.name == name) {
                    matchItem = item;
                  }
                });
              }

              if (matchItem) {
                vm.remain_variants.push(matchItem.id);
                newVariant.push({
                  id: matchItem.id,
                  name: name,
                  price_currency: matchItem.price_currency,
                  photo: matchItem.photo.url,
                  price: matchItem.price_currency == 'RMB' || matchItem.price_currency == 'USD' ? Number(matchItem.price_satoshi / 100).toFixed(2) : Number(matchItem.price_satoshi / 100000000).toFixed(8),
                  discounted_price: matchItem.price_currency == 'RMB' || matchItem.price_currency == 'USD' ? Number(matchItem.discounted_price_satoshi / 100).toFixed(2) : Number(matchItem.discounted_price_satoshi / 100000000).toFixed(8),
                  sku: matchItem.sku,
                });
              } else {
                newVariant.push({
                  id: id,
                  name: name,
                  photo: '',
                  price_currency: $(
                    'select[name="product[price_currency]"]'
                  ).val(),
                  price: $('input[name="product[price]"]').val(),
                  quantity: 0,
                  sku:
                    $('input[name="product[sku]"]').val() +
                    first.name +
                    " / " +
                    sec,
                });
              }
            });
          }
        );
      }
      return newVariant;
    },
    checkVariantName(index)
    {
      let currentVariantName = this.product_variants[index].name;
      let varaintNames = [];
      let vm = this;
      let is_repeated = false;
      this.product_variants.forEach(function(item, key){
        if(item.delete != 'true') {
          if(!varaintNames.includes(currentVariantName)){
            varaintNames.push(item.name);
            vm.product_variants[key].name_error = '';
          }
          else {  
            is_repeated = true;
            vm.product_variants[key].name_error = currentVariantName + (vm.locale == 'en' ?  'is repeated' : ' 重复');
            $('input[type=submit]').css('display', 'none');
          }
        }
      });

      if(!is_repeated) {
        $('input[type=submit]').css('display', 'block');
      }
    }
  },
  computed: {
    // productsArr() {
    //   var options = this.product_variants.map((obj) => obj.options);
    //   return this.generateVariations(options);
    // },
  },
};
</script>

<style lang="scss">
$main-color: #f9b73e;
$border: #dadada;
.fixedFont {
  font-size: 16px;
}
.special {
  font-weight: 400;
  margin: 0;
  margin-bottom: 1.3rem;
}
.innerSetup {
  height: max-content;
  padding: 2rem;
  h3 {
    margin: 0;
  }
  p {
    cursor: pointer;
    font-size: 14px;
    color: $main-color;
  }
  .rows {
    padding: 0;
    input {
      padding: 0.5rem;
    }
  }
  .md-table-cell-container,
  .md-table-head-container,
  .md-table-head-label {
    padding: 6px 24px 6px 0;
    font-size: 16px !important;
  }
  table {
    color: inherit;
    th,
    td {
      color: inherit;
      border: none;
    }
    .md-table-head-container,
    .md-table-head-label {
      padding-left: 0;
    }
    th,
    tr {
      &:first-child {
        padding-right: 3.5rem;
        max-width: 220px;
        width: 190px;
      }
      &:nth-child(2) {
        width: 180px;
      }
      &:nth-child(3) {
        width: 140px;
      }
      font-size: 16px;
    }
  }
}
div {
  .innerSetup {
    border-top: 1px solid $border;
  }
}
.variation_inputy {
  height: max-content;
  h5,
  p {
    margin: 0;
    margin-bottom: 0.8rem;
  }
  p {
    cursor: pointer;
  }
  .wording {
    display: flex;
    justify-content: space-between;
  }
  .Variations {
    height: max-content;
    margin-bottom: 0.8rem;
    display: flex;
    justify-content: space-between;
    // .v-row {
    //   width: 30%;
    // }
    display: grid;
    grid-template-columns: 70% auto;
    grid-gap: 20px;
    input {
      margin: 0 1rem 0 0;
      height: 50px;
    }
    .form-control {
      height: 45px !important;
    }
    .addVariation {
      margin: 0;
      padding: 8px 0.5rem 5px;
      // border: 1px solid black;
      border: 1px solid #e5e5e5 !important;
      border-radius: 5px !important;
      min-height: 45px;
      .md-chip {
        background: #f9b73e77;
        height: 29px;
      }
      input {
        height: 29px;
      }
    }
  }
}

.check {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-top: 0.8rem;
  cursor: pointer;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  input:checked ~ .checkmark {
    background-color: $main-color;
    &::after {
      display: block;
    }
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: inherit;
    border: 1px solid $border;
    &::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }
  &-words {
    vertical-align: text-top;
  }
}

.option-inner {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;

  span {
    background-color: $main-color;
    padding: 5px 30px 7px 15px;
    border-radius: 15px;
    color: #fff;
    margin-right: 8px;
    font-size: 14px;
    line-height: 1;
    position: relative;
    margin-bottom: 5px;
  }

  .delete {
    padding: 0;
    position: absolute;
    text-transform: lowercase;
    right: 10px;
    color: red;
    margin: 0;
    cursor: pointer;
    transition: 0.5s opacity;
  }
  .delete:hover {
    opacity: 0.5;
  }
}

.error {
  margin-top: 5px;
  font-size: 14px;
  color: red;
}

.xpay-tooltip {
  position: relative;
}
.xpay-tooltip span.icon {
  background: #262a2e;
  border-radius: 50%;
  line-height: 0.6;
  font-weight: bold;
  color: #fff;
  padding: 5px;
  font-size: 10px;
  width: 15px;
  height: 15px;
  display: inline-block;
  text-align: center;
  margin-left: 5px;
}
.xpay-tooltip .tooltiptext-right {
  visibility: hidden;
  width: 300px;
  padding: 5px 0;
  border-radius: 6px;
  text-align: center;
  color: #fff;
  background-color: #000;
  opacity: 0;
  transition: opacity 1s;
  /* POSITIONING */
  z-index: 1;
  position: absolute;
  left: 105%;
  top: -5px;
  text-align: left;
  padding: 10px 15px;
}

.xpay-tooltip:hover .tooltiptext-right {
	visibility: visible;
	opacity: 1;
}
.inline-input {
  display: flex;
  align-items: center;
}
.inline-input .plus {
  font-size: 30px;
}
.inline-input .plus:hover {
  cursor: pointer;
  color: #f9b73e;
}
@media screen and (max-width: 600px) {
  .variation_inputy .Variations {
    height: auto;
    display: block;
    margin-bottom: 1.5rem;
  }

  .variation_inputy .Variations .v-row {
    width: 100%;
    margin-bottom: 1rem;
  }
}
</style>
