<template>
  <div class="image" :class="styleClass" @drop="dropFile" @dragover.prevent @dragenter.prevent>
    <img v-if="image != null" :src="image">
    <label v-if="image != null && !disabled" :for="idKey" class="image-label">{{changeLabel}}</label>
    <label v-if="image == null" :for="idKey" class="image-placeholder">{{imagePlaceholder}}</label>
    <input type="file" :id="idKey" :name="name" accept="image/*" @change="selectImage">
  </div>
</template>

<script>
  export default {
    props: ['name', 'idKey', 'value', 'changeLabel', 'imagePlaceholder', 'styleClass', 'disabled'],
    data() {
      return {
        image: null,
      }
    },
    computed: {},
    watch: {
      image: function(val) {
        this.$emit('documentFound', val != null);
        this.$emit('documentUpdate', val)
      }
    },
    mounted() {
      if(typeof this.value != 'undefined' && this.value != ''){
        this.image = this.value;
      }
    },
    methods: {
      // selectImage(e) {
      async selectImage(e) {
        const file = e.target.files[0];
        // this.image = file ? URL.createObjectURL(file) : (this.image ? this.image : null);
        this.image = file ? await this.toBase64(file) : (this.image ? this.image : null);
      },
      dropover(e) {
        e.preventDefault();
      },
      dropFile(e) {
        e.preventDefault();
      },
      toBase64(file) {
        return new Promise((resolve, reject) => {
          let reader = new FileReader()
          reader.onload = () => {
            resolve(reader.result)
          }
          reader.onerror = reject
          reader.readAsDataURL(file)
        })
      },
    },
  }
</script>

<style scope>
input[type="file"] {
  display: none;
}
.image-label {
  font-weight: bold;
  display: inline-block;
  text-align: left;
  padding: 0;
  color: #ECBC7B;
}
label {
  cursor: pointer;
}
.small .image-placeholder{
  padding: 10px;
}
img {
  max-width: 150px;
  display: block;
}
</style>